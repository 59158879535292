import { Button, Col, Layout, message, Row, Spin, Table, Tooltip, Typography } from 'antd';
import { AuditPayload } from 'interfaces';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as AuditsSlice from 'slices/audits';
import { useMyAccount } from 'slices/myAccount';
import { useAppDispatch } from 'store/store';
import { timezones } from 'utils/timezone';

const RequestsPage = () => {
  const [timezone] = useState<keyof typeof timezones>('UZ');
  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();

  const { myAccount } = useMyAccount();
  return (
    <Spin spinning={false}>
      <Layout className={'accounts-list-container'}>
        <Layout.Content>
          <Row gutter={16}>
            <Col span={12}>
              <Typography.Title style={{ margin: '16px 0' }} level={3}>
                DRIVER REQUESTS
              </Typography.Title>
            </Col>
            <Col span={12}>
              <Typography.Title style={{ margin: '16px 0' }} level={3}>
                ASSIGNED REQUESTS
              </Typography.Title>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={12}>
              <Table
                className="content"
                pagination={{
                  pageSizeOptions: [
                    '10',
                    '50',
                    '100',
                    '250',
                    '500',
                    '1000',
                  ],
                  showSizeChanger: true,
                }}
                dataSource={[]}
              >
                <Table.Column title="#" dataIndex="number" key="number" />

                <Table.Column
                  title="COMPANY"
                  dataIndex="companyName"
                  key="companyName"
                  render={(companyName: string, data) => {
                    return companyName ? <Tooltip title={companyName}>{companyName}</Tooltip> : '-';
                  }}
                />
                <Table.Column
                  title="DRIVER"
                  dataIndex="driverName"
                  key="driverName"
                  render={(driverName: string) => {
                    return driverName ? <Tooltip title={driverName}>{driverName}</Tooltip> : '-';
                  }}
                />
                <Table.Column
                  title="REQUEST TYPE"
                  dataIndex="requestType"
                  key="requestType"
                  render={(requestType: string) => {
                    return requestType;
                  }}
                />
                <Table.Column
                  title="REQUEST TIME"
                  dataIndex="createdAt"
                  key="createdAt"
                  render={(createdAt: string) => {
                    return createdAt ? moment(createdAt).tz(timezones[timezone]).format('DD.MM.YYYY HH:mm') : '-';
                  }}
                />

                {[
                  'admin',
                  'manager',
                ].includes(myAccount?.role || '') ? (
                  <Table.Column
                    title="ACTION"
                    dataIndex="clientResourceId"
                    key="clientResourceId"
                    render={(clientResourceId, audit: AuditPayload) => (
                      <Button
                        onClick={async () => {
                          const { status, msg } = await appDispatch(AuditsSlice.deleteAudit(audit));
                          if (status === 'ok') {
                            message.success('Audit has been deleted');
                          } else {
                            message.error(msg || 'Error');
                          }
                        }}
                      >
                        DELETE
                      </Button>
                    )}
                  />
                ) : null}
              </Table>
            </Col>

            <Col xs={12}>
              <Table
                className="content"
                pagination={{
                  pageSizeOptions: [
                    '10',
                    '50',
                    '100',
                    '250',
                    '500',
                    '1000',
                  ],
                  showSizeChanger: true,
                }}
                dataSource={[]}
              >
                <Table.Column title="#" dataIndex="number" key="number" />

                <Table.Column
                  title="COMPANY"
                  dataIndex="companyName"
                  key="companyName"
                  render={(companyName: string, data) => {
                    return companyName ? <Tooltip title={companyName}>{companyName}</Tooltip> : '-';
                  }}
                />
                <Table.Column
                  title="DRIVER"
                  dataIndex="driverName"
                  key="driverName"
                  render={(driverName: string) => {
                    return driverName ? <Tooltip title={driverName}>{driverName}</Tooltip> : '-';
                  }}
                />
                <Table.Column
                  title="REQUEST TYPE"
                  dataIndex="requestType"
                  key="requestType"
                  render={(requestType: string) => {
                    return requestType;
                  }}
                />
                <Table.Column
                  title="REQUEST TIME"
                  dataIndex="createdAt"
                  key="createdAt"
                  render={(createdAt: string) => {
                    return createdAt ? moment(createdAt).tz(timezones[timezone]).format('DD.MM.YYYY HH:mm') : '-';
                  }}
                />

                {[
                  'admin',
                  'manager',
                ].includes(myAccount?.role || '') ? (
                  <Table.Column
                    title="ACTION"
                    dataIndex="clientResourceId"
                    key="clientResourceId"
                    render={(clientResourceId, audit: AuditPayload) => (
                      <Button
                        onClick={async () => {
                          const { status, msg } = await appDispatch(AuditsSlice.deleteAudit(audit));
                          if (status === 'ok') {
                            message.success('Audit has been deleted');
                          } else {
                            message.error(msg || 'Error');
                          }
                        }}
                      >
                        DELETE
                      </Button>
                    )}
                  />
                ) : null}
              </Table>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </Spin>
  );
};
export default RequestsPage;
