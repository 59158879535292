import { Button, Col, DatePicker, Row, Select, Tooltip } from 'antd';
import { useAuthConnectionEffect } from 'hooks/useAuthConnectionEffect';
import { CompanyPayload, DriverPayload, ManualPatchPayload } from 'interfaces';
import moment from 'moment';
import * as R from 'ramda';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as CompaniesSlice from 'slices/companies';
import { useCompanies } from 'slices/companies';
import { useDrivers } from 'slices/drivers';
import { UpdateManualPatchPayload } from 'slices/manualPatch';
import { ResponsePayload } from 'slices/subscriptive';
import { compareTwoStrings } from 'string-similarity';
import { getContainer } from 'utils/html';
import { timezones } from '../utils/timezone';

export default function SelectStep(props: {
  onlyLast8Days: boolean;
  patch: ManualPatchPayload;
  updatePatch: (patch: UpdateManualPatchPayload, reset?: boolean) => Promise<ResponsePayload>;
  disabled: boolean | undefined;
  fetchEvents: () => void;
}) {
  const { updatePatch, patch, disabled, fetchEvents } = props;
  const { drivers, driversSubscribed, driversLoading } = useDrivers(patch.companyId || null);
  const dispatch = useDispatch();
  const { companies, companiesSubscribed, companiesLoading } = useCompanies();
  useAuthConnectionEffect(() => {
    dispatch(CompaniesSlice.subscribe());
    return () => {
      dispatch(CompaniesSlice.unsubscribe());
    };
  }, []);
  const { startDate, endDate } = useMemo(
    () => ({
      startDate: patch.from ? moment(patch.from) : null,
      endDate: patch.to ? moment(patch.to) : null,
    }),
    [
      patch.from,
      patch.to,
    ]
  );
  const driverLogsUrl = useMemo(() => {
    const hosPortalUrl = process.env.REACT_APP_HOS_PORTAL_URL;
    if (!hosPortalUrl) {
      return undefined;
    }

    if (patch.driverId !== null && drivers && drivers.length > 0 && companies.length > 0) {
      const selectedCompany = companies.filter((company) => company._id === patch.companyId)[0];
      const driverHomeTerminalId = drivers?.filter((driver) => driver._id === patch.driverId)[0]?.driverInfo
        ?.homeTerminal.id;
      const driverTimezone =
        driverHomeTerminalId !== undefined
          ? selectedCompany.terminals.filter((terminal) => terminal.id === driverHomeTerminalId)[0].timeZone.id
          : selectedCompany.timeZone.id;

      return `${hosPortalUrl}/portal/log/${patch.driverId}/${moment
        .tz(timezones[driverTimezone])
        .format('M-D-YYYY')}/0/${driverTimezone}`;
    }

    return undefined;
  }, [
    drivers,
    companies,
    patch.driverId,
  ]);
  const eightDaysAgo = moment().subtract(8, 'days');
  return (
    <>
      <Row
        gutter={[
          8,
          8,
        ]}
      >
        <Col>
          <Tooltip
            mouseEnterDelay={1}
            getPopupContainer={getContainer}
            getTooltipContainer={getContainer}
            title="Select company"
          >
            <Select
              placeholder={'Select company'}
              loading={companiesLoading}
              disabled={disabled || !companiesSubscribed}
              filterOption={(str, option) => {
                const name = option?.children.toLowerCase().replaceAll(' ', '');
                const searchStr = str.toLowerCase().replaceAll(' ', '');
                return !str || name.includes(searchStr) || compareTwoStrings(name, searchStr) > 0.8;
              }}
              onChange={(companyId: string) =>
                companyId !== patch.companyId &&
                updatePatch(
                  R.pipe<ManualPatchPayload, ManualPatchPayload, ManualPatchPayload>(
                    R.assoc('companyId', companyId),
                    R.assoc('driverId', null)
                  )(patch)
                )
              }
              value={patch.companyId || undefined}
              showSearch
              getPopupContainer={getContainer}
              style={{ minWidth: 300 }}
            >
              {companies.map((company: CompanyPayload) => (
                <Select.Option key={company._id} value={company._id}>
                  {company.name}
                </Select.Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip
            mouseEnterDelay={1}
            getPopupContainer={getContainer}
            getTooltipContainer={getContainer}
            title="Select driver"
          >
            <Select
              placeholder={'Select driver'}
              loading={driversLoading}
              disabled={
                disabled ||
                !driversSubscribed ||
                (patch.driverId !== null && drivers?.find((driver) => driver._id === patch.driverId) === undefined)
              }
              filterOption={(str, option) => {
                const name = option?.children.join('').toLowerCase();
                const searchStr = str.toLowerCase();
                return !str || name.includes(str.toLowerCase()) || compareTwoStrings(name, searchStr) > 0.8;
              }}
              onChange={(driverId: string) =>
                patch.driverId !== driverId && updatePatch(R.assoc('driverId', driverId, patch))
              }
              value={patch.driverId || undefined}
              showSearch
              getPopupContainer={getContainer}
              style={{ minWidth: 300 }}
            >
              {drivers?.map((driver: DriverPayload) => (
                <Select.Option key={driver._id} value={driver._id}>
                  {driver.firstName} {driver.lastName}
                </Select.Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
        <Col>
          <DatePicker.RangePicker
            disabledDate={(date) => (props.onlyLast8Days ? eightDaysAgo.isAfter(date) : false)}
            disabled={disabled}
            allowClear={false}
            // getPopupContainer={getContainer}
            value={[
              startDate,
              endDate,
            ]}
            onChange={(range) => {
              if (range) {
                updatePatch({
                  ...patch,
                  from: range[0] ? moment.utc(range[0].format('YYYY-MM-DD'))?.toISOString() : null,
                  to: range[1] ? moment.utc(range[1].format('YYYY-MM-DD'))?.toISOString() : null,
                });
              }
            }}
          />
        </Col>
        <Col>
          <Button disabled={disabled} type="primary" htmlType="submit" onClick={() => fetchEvents()}>
            LOAD
          </Button>
        </Col>
        {driverLogsUrl && (
          <Col>
            <Tooltip title={`Please log in to HOS portal of ${patch.companyName} before using`}>
              <a
                href={driverLogsUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  paddingTop: 0,
                  lineHeight: '30px',
                }}
              >
                Go to driver's log
              </a>
            </Tooltip>
          </Col>
        )}
      </Row>
    </>
  );
}
