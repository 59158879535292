import { Layout, Spin } from 'antd';
import { useAuthConnectionEffect } from 'hooks/useAuthConnectionEffect';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { initConnectionAsync, selectConnection } from 'slices/connection';
import * as MyAccountSlice from 'slices/myAccount';
import useErrorBoundary from 'use-error-boundary';
import AccountListPage from 'views/accounts';
import AccountDetailsPage from 'views/accounts/details';
import AuditsPage from 'views/audit';
import DotPage from 'views/dot';
import ManualPatchesPage from 'views/manual-patch';
import ManualPatchDetails from 'views/manual-patch/details';
import RequestsPage from 'views/requests';
import SubscriptionLockPage from 'views/subscriptionLocks';
import Header from '../components/Header';
import './App.css';
import Login from './login';
import AlertsPage from "./alert";

const App = () => {
  const { ErrorBoundary, didCatch, error } = useErrorBoundary();
  const { authenticated, loading } = useSelector(selectConnection);
  const location = useLocation();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initConnectionAsync());
  }, []);
  useAuthConnectionEffect(() => {
    dispatch(MyAccountSlice.subscribe({}));
    return () => {
      dispatch(MyAccountSlice.unsubscribe({}));
    };
  });

  return (
    <>
      {didCatch ? (
        <Layout>
          <Header />
          <Layout.Content
            style={{
              height: 'calc(100vh - 64px)',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <strong className="text-center" style={{ flexGrow: 1 }}>
              An error has been caught: {error.message}
            </strong>
          </Layout.Content>
        </Layout>
      ) : (
        <ErrorBoundary>
          <Switch location={location}>
            <Route path="/login" exact>
              <Login />
            </Route>
            {authenticated || loading ? (
              <>
                <Spin spinning={loading}>
                  <Layout>
                    <Header />
                    <Switch>
                      <Route path="/accounts/:accountId" children={<AccountDetailsPage />} />
                      <Route path="/accounts" children={<AccountListPage />} />
                      <Route path="/activity" children={<ManualPatchesPage />} exact />
                      <Route path="/activity/:manualPatchId" exact children={<ManualPatchDetails />} />
                      <Route path="/audits" children={<AuditsPage />} />
                      <Route path="/alerts" children={<AlertsPage />} />
                      <Route path="/dot" children={<DotPage />} />
                      <Route path="/subscription-lock" children={<SubscriptionLockPage />} />
                      <Route path="/driver-requests" children={<RequestsPage />} />
                      <Redirect
                        path="/"
                        exact
                        to={{
                          pathname: '/activity',
                        }}
                      />
                    </Switch>
                  </Layout>
                </Spin>
              </>
            ) : (
              !loading && (
                <Redirect
                  to={{
                    pathname: '/login',
                  }}
                />
              )
            )}
          </Switch>
        </ErrorBoundary>
      )}
    </>
  );
};

export default App;
