import { FileAddOutlined } from '@ant-design/icons';
import { useDebounce, useLocalStorageState } from 'ahooks';
import { Button, Col, InputNumber, Layout, message, Radio, Row, Select, Space, Tooltip, Typography } from 'antd';
import ManualPatchesTable from 'components/ManualPatchesTable';
import { useAuthConnectionEffect } from 'hooks/useAuthConnectionEffect';
import { CompanyPayload, DriverPayload } from 'interfaces';
import * as R from 'ramda';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as AccountsSlice from 'slices/accounts';
import { useAccounts } from 'slices/accounts';
import * as CompaniesSlice from 'slices/companies';
import { useCompanies } from 'slices/companies';
import * as DriversSlices from 'slices/drivers';
import { useDrivers } from 'slices/drivers';
import { create } from 'slices/manualPatch';
import * as ManualPatchesSlice from 'slices/manualPatches';
import { useManualPatches } from 'slices/manualPatches';
import { useMyAccount } from 'slices/myAccount';
import { useAppDispatch } from 'store/store';
import { compareTwoStrings } from 'string-similarity';
import { timezones } from 'utils/timezone';
import { v4 } from 'uuid';

const ManualPatchesPage = () => {
  const [
    timezone,
    setTimezone,
  ] = useState<keyof typeof timezones>('UZ');
  const [
    companyId,
    setCompanyId,
  ] = useState<string | null>(null);
  const [
    driverId,
    setDriverId,
  ] = useState<string | null>(null);
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();

  const { companiesById, companiesSubscribed, companiesLoading } = useCompanies();
  const [
    limit,
    setLimit,
  ] = useLocalStorageState(
    'activity-limit',
    // @ts-expect-error ahooks options type is not exported
    200
  );
  const debouncedLimit = useDebounce(limit, {
    wait: 3000,
  });
  const { accounts, accountsSubscribed, accountsLoading } = useAccounts();
  const { patches, patchesLoading, patchesSubscribed } = useManualPatches();
  const accountsById = R.indexBy(R.prop('id'), accounts);
  const { myAccount, myAccountSubscribed, myAccountLoading } = useMyAccount();
  const { driversById, driversLoading, driversSubscribed } = useDrivers(companyId);
  const filteredManualPatches = patches.filter((patch) => {
    return (!companyId || patch.companyId === companyId) && (!driverId || patch.driverId === driverId);
  });
  useAuthConnectionEffect(() => {
    if (myAccount?.role) {
      dispatch(
        ManualPatchesSlice.subscribe({
          includeFinished: true,
          limit: debouncedLimit,
          lastUpdatedAt: null,
        })
      );
      if (['admin', 'manager'].includes(myAccount.role)) dispatch(AccountsSlice.subscribe());
      return () => {
        dispatch(
          ManualPatchesSlice.unsubscribe({
            includeFinished: true,
            limit: debouncedLimit,
          })
        );
        if (['admin', 'manager'].includes(myAccount.role)) dispatch(AccountsSlice.unsubscribe());
      };
    }
  }, [
    myAccount?.role,
    debouncedLimit,
  ]);

  useAuthConnectionEffect(() => {
    if (companyId) {
      dispatch(DriversSlices.subscribe(companyId));
      return () => {
        dispatch(DriversSlices.unsubscribe(companyId));
      };
    }
  }, [
    companyId,
  ]);

  useAuthConnectionEffect(() => {
    dispatch(CompaniesSlice.subscribe());
    return () => {
      dispatch(CompaniesSlice.unsubscribe());
    };
  }, []);
  return (
    <Layout className={'accounts-list-container'}>
      <Row justify={'space-between'}>
        <Col span={6}>
          <Typography.Title style={{ margin: '16px 0' }} level={3}>
            ACTIVITY
          </Typography.Title>
        </Col>

        <Col
          span={3}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Space size={20}>
            {![
              'shifter_hr',
              'shifter_day',
              'shifter_hr_day',
              'unrestricted_shifter_hr_day',
            ].includes(myAccount?.role || '') ? (
              <Space>
                <div>LIMIT</div>
                <InputNumber value={limit} min={10} onChange={setLimit} />
              </Space>
            ) : null}

            <Button
              icon={<FileAddOutlined />}
              key="createButton"
              type="primary"
              disabled={
                ![
                  'admin',
                  'manager',
                  'shifter_hr',
                  'shifter_day',
                  'shifter_hr_day',
                  'unrestricted_shifter_hr_day',
                ].includes(myAccount?.role || '')
              }
              style={{ marginLeft: 'auto' }}
              onClick={async () => {
                const clientResourceId = v4();
                const { status, msg, data } = await appDispatch(
                  create({
                    clientResourceId,
                    companyId,
                    driverId,
                    type: 'days',
                  })
                );
                if (status === 'ok') {
                  console.log('data', data);
                  if (data?.id) {
                    window.open(`/activity/${data?.id}`, '_blank')?.focus();
                  }
                  message.success('Patch has been created');
                } else {
                  message.error(msg || 'Error');
                }
              }}
            >
              CREATE
            </Button>
          </Space>
        </Col>
      </Row>
      <Layout.Content>
        <div className={'content'}>
          <div style={{ display: 'flex', padding: 16 }}>
            <Row
              gutter={[
                8,
                8,
              ]}
            >
              <Col>
                <Tooltip
                  mouseEnterDelay={1}
                  getPopupContainer={(node: HTMLElement) => node.parentNode as HTMLElement}
                  getTooltipContainer={(node: HTMLElement) => node.parentNode as HTMLElement}
                  title="Select company"
                >
                  <Select
                    loading={companiesLoading}
                    disabled={!companiesSubscribed}
                    placeholder={'Select company'}
                    filterOption={(str, option) => {
                      const name = option?.children.toLowerCase().replaceAll(' ', '');
                      const searchStr = str.toLowerCase().replaceAll(' ', '');
                      return !str || name.includes(searchStr) || compareTwoStrings(name, searchStr) > 0.8;
                    }}
                    onChange={(companyId: string) => {
                      setCompanyId(companyId);
                      setDriverId(null);
                    }}
                    value={companyId || undefined}
                    showSearch
                    getPopupContainer={(node: HTMLElement) => node.parentNode as HTMLElement}
                    style={{ minWidth: 150 }}
                  >
                    {Object.values(companiesById).map((company: CompanyPayload) => (
                      <Select.Option key={company._id} value={company._id}>
                        {company.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Tooltip>
              </Col>
              <Col>
                <Tooltip
                  mouseEnterDelay={1}
                  getPopupContainer={(node: HTMLElement) => node.parentNode as HTMLElement}
                  getTooltipContainer={(node: HTMLElement) => node.parentNode as HTMLElement}
                  title="Select driver"
                >
                  <Select
                    loading={driversLoading}
                    disabled={!driversSubscribed}
                    placeholder={'Select driver'}
                    filterOption={(str, option) => {
                      const name = option?.children.join('').toLowerCase();
                      const searchStr = str.toLowerCase();
                      return !str || name.includes(str.toLowerCase()) || compareTwoStrings(name, searchStr) > 0.8;
                    }}
                    onChange={(driverId: string) => setDriverId(driverId)}
                    value={driverId || undefined}
                    showSearch
                    getPopupContainer={(node: HTMLElement) => node.parentNode as HTMLElement}
                    style={{ minWidth: 150 }}
                  >
                    {Object.values(driversById || {}).map((driver: DriverPayload) => (
                      <Select.Option key={driver._id} value={driver._id}>
                        {driver.firstName} {driver.lastName}
                      </Select.Option>
                    ))}
                  </Select>
                </Tooltip>
              </Col>
              {/*<DatePicker.RangePicker*/}
              {/*  allowClear={false}*/}
              {/*  getPopupContainer={(node: HTMLElement) =>*/}
              {/*    node.parentNode as HTMLElement*/}
              {/*  }*/}
              {/*  value={[startDate, endDate]}*/}
              {/*  onChange={(range) => {*/}
              {/*    if (range) {*/}
              {/*      updatePatch({*/}
              {/*        ...patch,*/}
              {/*        from: range[0]?.toISOString(),*/}
              {/*        to: range[1]?.toISOString(),*/}
              {/*      });*/}
              {/*    }*/}
              {/*  }}*/}
              {/*/>*/}
            </Row>
            <Radio.Group value={timezone} style={{ marginLeft: 'auto' }} onChange={(e) => setTimezone(e.target.value)}>
              <Radio.Button value="UZ">UZ</Radio.Button>
              <Radio.Button value="ET">ET</Radio.Button>
              <Radio.Button value="CT">CT</Radio.Button>
              <Radio.Button value="MT">MT</Radio.Button>
              <Radio.Button value="PT">PT</Radio.Button>
            </Radio.Group>
          </div>
          <ManualPatchesTable
            loading={patchesLoading || !patchesSubscribed || myAccountLoading || !myAccountSubscribed}
            account={myAccount || null}
            accountsById={accountsById}
            manualPatches={filteredManualPatches.map((patch) => ({ ...patch, key: patch.id }))}
            timezone={timezone}
          />{' '}
        </div>
      </Layout.Content>
    </Layout>
  );
};
export default ManualPatchesPage;
