import { DatePicker, Form, Input, InputNumber, Modal, Select } from 'antd';
import moment from 'moment-timezone';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuthConnectionEffect } from '../hooks/useAuthConnectionEffect';
import { LogEvent } from '../interfaces';
import * as VehiclesSlices from '../slices/vehicles';
import { useVehicles } from '../slices/vehicles';
import { timezones } from '../utils/timezone';
import { eventLabels, originLabels } from '../views/manual-patch/details';

const { Option } = Select;

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

interface Values {
  id: number;
  time: moment.Moment;
  type: string;
  origin: string;
  odometer: string;
  engineHours: string;
  locationSource: string;
  positioning: string;
  latitude: string;
  longitude: string;
  notes: string;
  vehicleId: string;
}

type EventUpdateFormProps = {
  isVisible: boolean;
  onConfirm: (event: LogEvent) => void;
  onCancel: () => void;
  event: LogEvent & { odometerOffset?: number };
};

const EventUpdateForm: React.FC<EventUpdateFormProps> = ({ isVisible, onConfirm, onCancel, event }) => {
  const { vehiclesById, vehiclesLoading, vehiclesSubscribed } = useVehicles(event.companyId);
  const dispatch = useDispatch();
  const [form] = Form.useForm<Values>();
  const [
    isCertificationEvent,
    setIsCertificationEvent,
  ] = useState(false);
  const [
    isAuthenticationEvent,
    setIsAuthenticationEvent,
  ] = useState(false);
  const [
    isOriginReadOnly,
    setIsOriginReadOnly,
  ] = useState(false);

  useAuthConnectionEffect(() => {
    if (event.companyId) {
      dispatch(VehiclesSlices.subscribe(event.companyId));
      return () => {
        dispatch(VehiclesSlices.unsubscribe(event.companyId));
      };
    }
  }, [event.companyId]);

  const onTypeChange = (value: string) => {
    if (
      [
        'LOG_NORMAL_PRECISION',
        'ENG_DOWN_NORMAL',
        'ENG_UP_NORMAL',
      ].includes(value)
    ) {
      form.setFieldsValue({ origin: 'ELD' });
      setIsOriginReadOnly(true);
    } else {
      setIsOriginReadOnly(false);
    }
  };

  useEffect(() => {
    if (!vehiclesSubscribed) {
      return;
    }

    form.setFieldsValue({
      id: event.seqId ? parseInt(event.seqId, 16) : undefined,
      time: moment.tz(
        event.eventTime.timestamp,
        timezones[event.eventTime.logDate.timeZone.id] || 'America/Los_Angeles'
      ),
      type: event.eventCode.id,
      origin: event.recordOrigin?.id || 'DRIVER',
      odometer: event.totalVehicleMiles
        ? (event.totalVehicleMiles + (event.odometerOffset || 0)).toString()
        : undefined,
      engineHours: event.totalEngineHours?.toString(),
      locationSource:
        event.isLive === undefined
          ? 'Not Selected'
          : event.isLive
          ? 'Location generated when connected to ECM'
          : 'Location when not connected to ECM',
      positioning:
        event.driverLocationDescription !== undefined
          ? 'Manual'
          : event.location !== undefined && event.location.calculatedLocation !== undefined
          ? 'Automatic'
          : 'Not Selected',
      latitude: event.location?.lat?.toString(),
      longitude: event.location?.lon?.toString(),
      notes: event.eventComment,
      vehicleId: Object.values(vehiclesById || {})
        .filter((vehicle) => vehicle.active)
        .find((vehicle) => vehicle.name === event.vehicle.name)?._id,
    });

    if (event.eventCode.id.startsWith('DR_CERT')) {
      setIsCertificationEvent(true);
    } else {
      setIsCertificationEvent(false);
    }

    if (
      [
        'DR_LOGIN',
        'DR_LOGOUT',
      ].includes(event.eventCode.id)
    ) {
      setIsAuthenticationEvent(true);
    } else {
      setIsAuthenticationEvent(false);
    }

    if (
      [
        'LOG_NORMAL_PRECISION',
        'ENG_DOWN_NORMAL',
        'ENG_UP_NORMAL',
      ].includes(event.eventCode.id)
    ) {
      setIsOriginReadOnly(true);
    } else {
      setIsOriginReadOnly(false);
    }
  }, [
    event,
    form,
    vehiclesSubscribed,
  ]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values: Values) => {
        form.resetFields();
        const updatedMomentTime = values.time.isUtcOffset()
          ? values.time
          : values.time.tz((event && timezones[event.eventTime.logDate.timeZone.id]) || 'America/Los_Angeles', true);
        const updatedEvent = R.mergeDeepRight<LogEvent, DeepPartial<LogEvent>>(event!, {
          eventTime: {
            // https://github.com/ant-design/ant-design/issues/11012#issuecomment-1177527182
            timestamp: updatedMomentTime.valueOf(),
            logDate: {
              ...event?.eventTime.logDate,
              date: updatedMomentTime.format('yyyy/MM/DD'),
            },
          },
          eventCode: {
            id: values.type,
          },
          recordOrigin: {
            id: values.origin,
          },
          totalVehicleMiles: values.odometer ? +values.odometer - (event?.odometerOffset || 0) : undefined,
          totalEngineHours: values.engineHours ? +values.engineHours : undefined,
          location: {
            lat: values.latitude ? +values.latitude : undefined,
            lon: values.longitude ? +values.longitude : undefined,
          },
          eventComment: values.notes || undefined,
        });

        if (values.vehicleId && vehiclesById) {
          const vehicle = vehiclesById[values.vehicleId];
          updatedEvent.vehicle = {
            name: vehicle.name,
            id: vehicle._id,
            vin: vehicle.vin,
          };
        }

        onConfirm(updatedEvent as LogEvent);
      })
      .catch(() => {
        // Validation failed
      });
  };

  return (
    <Modal visible={isVisible} title="Edit event" onCancel={onCancel} onOk={handleSubmit}>
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            handleSubmit();
          }
        }}
        name="event_update_form"
      >
        <Form.Item name="id" label="ID">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item name="time" label="Time">
          <DatePicker showTime style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name="type" label="Type">
          <Select onChange={onTypeChange}>
            {Object.keys(eventLabels).map((key) => (
              <Option value={key} key={key}>
                {eventLabels[key]}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="origin"
          label="Origin"
          rules={[
            {
              required: true,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  value !== 'DRIVER' &&
                  [
                    'DS_OFF',
                    'DS_SB',
                    'DR_IND_PC',
                    'DR_IND_YM',
                  ].includes(getFieldValue('type'))
                ) {
                  return Promise.reject(new Error("Origin must be 'Driver' for the set event type."));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Select className={isOriginReadOnly ? 'read-only' : undefined} open={isOriginReadOnly ? false : undefined}>
            {Object.keys(originLabels).map((key) => (
              <Option value={key} key={key}>
                {originLabels[key]}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="vehicleId" label="Vehicle">
          <Select loading={vehiclesLoading} disabled={!vehiclesSubscribed} placeholder={'No Vehicle'}>
            {Object.values(vehiclesById || {})
              .filter((vehicle) => vehicle.active)
              .map((vehicle) => (
                <Select.Option key={vehicle._id} value={vehicle._id}>
                  {vehicle.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="odometer" label="Odometer" rules={[{ required: !isCertificationEvent }]}>
          <InputNumber min="0" precision={0} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name="engineHours" label="Engine Hours" rules={[{ required: !isCertificationEvent }]}>
          <InputNumber min="0" step="0.1" stringMode style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name="locationSource" label="Location Source">
          <Input value="Location generated when connected to ECM" disabled={true} />
        </Form.Item>
        <Form.Item name="positioning" label="Positioning">
          <Input value="Automatic" disabled={true} />
        </Form.Item>
        <Form.Item
          name="latitude"
          label="Latitude"
          rules={[{ required: !isCertificationEvent && !isAuthenticationEvent }]}
        >
          <InputNumber min="-90" max="90" step="0.000001" stringMode style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name="longitude"
          label="Longitude"
          rules={[{ required: !isCertificationEvent && !isAuthenticationEvent }]}
        >
          <InputNumber min="-180" max="180" step="0.000001" stringMode style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name="notes" label="Notes">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EventUpdateForm;
