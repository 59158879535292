import {
  CopyOutlined,
  DownOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Dropdown,
  InputNumber,
  Layout,
  Menu,
  message,
  Modal,
  Popconfirm,
  Progress,
  Radio,
  Row,
  Space,
  Spin,
  Switch,
  Tag,
  Tooltip,
} from 'antd';
import { ModalFuncProps } from 'antd/es/modal/Modal';
import ConditionalTooltip from 'components/ConditionalTooltip';
import SelectStep from 'components/SelectStep';
import { useAuthConnectionEffect } from 'hooks/useAuthConnectionEffect';
import {
  AlertPayload,
  AuditPayload,
  DotPayload,
  LogEvent,
  ManualPatchPayload,
  ManualPatchType,
  PatchLogEventPayload,
  SubscriptionLockPayload,
} from 'interfaces';
import moment from 'moment-timezone';
import * as R from 'ramda';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AutoSizer, List } from 'react-virtualized';
import * as AlertsSlice from 'slices/alerts';
import { useAlerts } from 'slices/alerts';
import * as AuditSlice from 'slices/audits';
import { useAudits } from 'slices/audits';
import * as DotSlice from 'slices/dots';
import { useDots } from 'slices/dots';
import * as DriverNoteSlice from 'slices/driverNote';
import * as DriverSlice from 'slices/drivers';
import { useDrivers } from 'slices/drivers';
import * as ManualPatchSlice from 'slices/manualPatch';
import { create, UpdateManualPatchPayload, useManualPatch } from 'slices/manualPatch';
import { useMyAccount } from 'slices/myAccount';
import * as PatchLogEventsSlice from 'slices/patchLogEvents';
import { usePatchLogEvents } from 'slices/patchLogEvents';
import * as SubscriptionLockSlice from 'slices/subscriptionLocks';
import { useSubscriptionLocks } from 'slices/subscriptionLocks';
import { useAppDispatch } from 'store/store';
import { getContainer } from 'utils/html';
import { getLogEvent, isCertification, isRestricted } from 'utils/patch-log';
import { timezones } from 'utils/timezone';
import { v4 } from 'uuid';
import DriverLogsChart, { ChartEvent, DrivingEventType } from '../../components/DriverLogsChart';
import EventUpdateForm from '../../components/EventUpdateForm';
import UserSelectionModal from '../../components/UserSelectionModal';
import { useDriverNote } from '../../slices/driverNote';

const { confirm } = Modal;
const { Panel } = Collapse;

export type ExtStatus = 'init' | 'uploading' | 'finished';
export const eventLabels: Record<string, string> = {
  DS_OFF: 'Off duty',
  LOG_NORMAL_PRECISION: 'Intermediate w/ CLP',
  DR_LOGIN: 'Login',
  DR_LOGOUT: 'Logout',
  DS_ON: 'On duty',
  DS_D: 'Driving',
  DS_SB: 'Sleeper',
  ENG_DOWN_NORMAL: 'Engine Shut-down w/ CLP',
  ENG_UP_NORMAL: 'Engine Power-up w/ CLP',
  DR_IND_YM: 'Yard Moves',
  DR_IND_PC: 'Personal Use',
  DR_CERT_1: 'Certification (1)',
  DR_CERT_2: 'Certification (2)',
  DR_CERT_3: 'Certification (3)',
  DR_CERT_4: 'Certification (4)',
  DR_CERT_5: 'Certification (5)',
  DR_CERT_6: 'Certification (6)',
  DR_CERT_7: 'Certification (7)',
  DR_CERT_8: 'Certification (8)',
  DR_CERT_9: 'Certification (9)',
};

export const diagnosticEventLabels: Record<string, string> = {
  D_POWER: 'Power data diagnostic',
  D_ENGINE_SYNC: 'Engine synchronization data diagnostic',
  D_MISSING_REQ_DATA: 'Missing required data elements data diagnostic',
  D_DATA_TRANSFER: 'Data transfer data diagnostic',
  D_UNIDENTIFIED_DRIVING_RECORDS: 'Unidentified driving records data diagnostic',
  D_OTHER: 'Other ELD identified diagnostic',
};

const getEventLabel = (event: LogEvent) => {
  const type = event.eventCode.id;

  if ((type === 'DIAG_LOGGED' || type === 'DIAG_CLEARED') && event.fc?.id !== undefined) {
    const label = diagnosticEventLabels[event.fc.id];

    return type === 'DIAG_CLEARED' ? `${label} (cleared)` : label;
  }

  if (type.startsWith('DR_CERT')) {
    const date = moment(event.certifiedRecordLogDate?.date).format('M/DD/YYYY');
    return `${eventLabels[type]} ${date}`;
  }

  return eventLabels[type] || type;
};

export const statusLabels: Record<string, string> = {
  ACTIVE: 'Active',
  INACTIVE_CHANGED: 'Inactive',
  INACTIVE_CHANGE_REQUESTED: 'Inactive',
  INACTIVE_CHANGE_REJECTED: 'Inactive',
};

export const originLabels: Record<string, string> = {
  ELD: 'Auto',
  DRIVER: 'Driver',
  OTHER_USER: 'Other User',
  UNIDENTIFIED_DRIVER: 'Unidentified Driver',
};

export const getEventStatus = (patchLogEvent: PatchLogEventPayload) => {
  if (patchLogEvent.failedAt) {
    return 'error';
  } else if (patchLogEvent.committedAt) {
    return 'done';
  } else if (!patchLogEvent.selected) {
    return 'ignored';
  } else {
    return 'pending';
  }
};

const convertMsToTime = (milliseconds: number): string => {
  const seconds = moment.duration(milliseconds).seconds().toString().padStart(2, '0');
  const minutes = moment.duration(milliseconds).minutes().toString().padStart(2, '0');
  const hours = Math.trunc(moment.duration(milliseconds).asHours());

  return `${hours}:${minutes}:${seconds}`;
};

const ManualPatchDetails = () => {
  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();
  console.time('RENDER');

  let warningModal: {
    destroy: () => void;
    update: (configUpdate: ModalFuncProps | ((prevConfig: ModalFuncProps) => ModalFuncProps)) => void;
  };
  useEffect(() => {
    let secondsToClose = 20;
    const message = process.env.REACT_APP_WARNING_MESSAGE;
    if (warningModal || typeof message !== 'string' || message.length === 0) {
      return;
    }

    warningModal = Modal.warning({
      title: message,
      content: `This modal will be closed after ${secondsToClose} second.`,
      closable: false,
      okButtonProps: {
        style: {
          display: 'none',
        },
      },
    });

    const timer = setInterval(() => {
      secondsToClose -= 1;
      warningModal.update({
        content: `This modal will be closed after ${secondsToClose} second.`,
      });
      if (secondsToClose === 0) {
        clearInterval(timer);
        warningModal.destroy();
      }
    }, 1000);
  }, []);

  let { manualPatchId } = useParams<{ manualPatchId: string }>();

  const { patch, patchSubscribed, patchLoading } = useManualPatch();

  const { auditsLoading, auditsSubscribed, auditsById } = useAudits();
  const { alertsById } = useAlerts();
  const { dots, dotsById, dotsLoading, dotsSubscribed } = useDots();
  const { subscriptionLocks, subscriptionLocksById, subscriptionLocksLoading, subscriptionLocksSubscribed } =
    useSubscriptionLocks();
  const { driverNote } = useDriverNote();

  useAuthConnectionEffect(() => {
    if (patch?.driverId && patch?.companyId) {
      dispatch(DriverNoteSlice.subscribe({ driverId: patch.driverId }));
      return () => {
        dispatch(DriverNoteSlice.unsubscribe({ driverId: patch.driverId }));
      };
    }
  }, [
    patch?.driverId,
    patch?.companyId,
  ]);

  const { patchLogEvents, patchLogEventsLoading, patchLogEventsSubscribed } = usePatchLogEvents(manualPatchId);

  const { driversById, driversSubscribed, driversLoading } = useDrivers(patch?.companyId || null) || {};

  const { myAccount, myAccountLoading, myAccountSubscribed } = useMyAccount();

  const role = myAccount?.role;

  const [
    patchType,
    setPatchType,
  ] = useState<ManualPatchType>(role === 'shifter_hr' ? 'hours' : 'days');

  const [
    fixCertifications,
    setFixCertifications,
  ] = useState(true);
  const history = useHistory();
  const [
    visible,
    setVisible,
  ] = useState<boolean>(false);

  const numberOfErrors = useMemo(() => {
    return patchLogEvents?.filter(({ failedAt }) => failedAt).length || 0;
  }, [patchLogEvents]);
  const numberOfSuccessful = useMemo(() => {
    return patchLogEvents?.filter(({ committedAt }) => committedAt).length || 0;
  }, [patchLogEvents]);
  const numberOfRolledBack = useMemo(() => {
    return patchLogEvents?.filter(({ rollbackAt }) => rollbackAt).length || 0;
  }, [patchLogEvents]);

  const totalNumber = patchLogEvents?.length || 0;
  const [
    shiftAmount,
    setShiftAmount,
  ] = useState(1);
  const totalSelected = useMemo(
    () =>
      !patchLogEvents
        ? 0
        : patchLogEvents.filter(
            (patchLogEvent) =>
              patchLogEvent.selected || (fixCertifications && isCertification(getLogEvent(patchLogEvent)))
          ).length,
    [
      fixCertifications,
      patchLogEvents,
    ]
  );

  const selectedNonCertificationEvents = useMemo(
    () => (patchLogEvents || []).filter((patchLogEvent) => patchLogEvent.selected),
    [patchLogEvents]
  );

  const sortedEvents = patchLogEvents || [];
  const list = useRef<List>(null);
  let [
    eventsWithErrorOrWarnings,
    currentEventWithErrorIdx,
  ] = useMemo(() => {
    const eventsWIthErrorsOrWarnings = (sortedEvents || []).reduce<number[]>((acc, event, idx) => {
      if (!!event.error || !!event.warning) {
        acc.push(idx);
      }

      return acc;
    }, []);

    return [
      eventsWIthErrorsOrWarnings,
      eventsWIthErrorsOrWarnings.length - 1,
    ];
  }, [sortedEvents]);

  const { hasErrorOrWarning, numberOfUnidentifiedEvents, numberOfInactiveEvents } = useMemo(() => {
    return {
      hasErrorOrWarning: sortedEvents.filter((event) => event.error || event.warning).length > 1,
      numberOfUnidentifiedEvents: sortedEvents.filter(
        (event) => event.originData.recordOrigin?.id === 'UNIDENTIFIED_DRIVER'
      ).length,
      numberOfInactiveEvents: sortedEvents.filter((event) => event.originData.recordStatus?.id.startsWith('INACTIVE_'))
        .length,
    };
  }, [sortedEvents]);

  const lastFinishedIndex = useMemo(
    () =>
      Math.min(
        R.findLastIndex((patchLogEvent) => !!patchLogEvent.committedAt, sortedEvents || []) + 5,
        sortedEvents.length - 1
      ),
    [sortedEvents]
  );

  const chartEvents = useMemo(() => {
    if (sortedEvents.length === 0 || patch?.to === undefined) {
      return [];
    }

    const events = sortedEvents
      .filter((event) =>
        [
          'DS_D',
          'DS_ON',
          'DS_SB',
          'DS_OFF',
          'DR_IND_YM',
          'DR_IND_PC',
        ].includes(event.originData.eventCode.id)
      )
      .map(
        (event) =>
          ({
            id: event.originData._id,
            type: event.originData.eventCode.id as DrivingEventType,
            timestamp: event.originData.eventTime.timestamp,
            timezone: timezones[event.originData.eventTime.logDate.timeZone.id] || 'America/Los_Angeles',
            duration: event.duration!,
          } as ChartEvent)
      );

    if (patch && patch.to && events.length > 0) {
      const lastEvent = events[events.length - 1];
      const patchEndTimestamp = Math.min(
        moment.tz(patch.to.slice(0, 19), lastEvent.timezone).add(1, 'd').valueOf(),
        moment().tz(lastEvent.timezone).valueOf()
      );
      lastEvent.duration = patchEndTimestamp - lastEvent.timestamp;
      events.push({
        id: 'unknown',
        type: lastEvent.type,
        timestamp: patchEndTimestamp,
        timezone: lastEvent.timezone,
        duration: 0,
      });
    }

    return events;
  }, [
    sortedEvents,
    patch?.to,
  ]);

  const patchDriverId = patch?.driverId;

  const testDrivers = useMemo(() => {
    if (driversById !== undefined && Object.keys(driversById).length > 0 && patchDriverId !== undefined) {
      const users = Object.values(driversById).filter((driver) => driver._id !== patchDriverId);
      return users.filter((driver) => {
        const fullNameLowerCase = `${driver.firstName} ${driver.lastName}`.toLowerCase();
        return (
          fullNameLowerCase.includes('test') &&
          fullNameLowerCase.includes('driver') &&
          !fullNameLowerCase.includes('audit')
        );
      });
    }
  }, [
    driversById,
    patchDriverId,
  ]);

  const isAdminOrShifter = useMemo(() => {
    return (
      role !== undefined &&
      [
        'admin',
        'manager',
        'shifter_hr',
        'shifter_day',
        'shifter_hr_day',
        'unrestricted_shifter_hr_day',
      ].includes(role)
    );
  }, [role]);

  const patchStatus = patch?.status;

  const patchIsShifted = useMemo(() => {
    return (
      patchStatus !== undefined &&
      [
        'shift_requested',
        'shift_started',
        'shift_stopped',
      ].includes(patchStatus)
    );
  }, [patchStatus]);

  const patchIsBeingFetched = useMemo(() => {
    return (
      patchStatus !== undefined &&
      [
        'fetch_requested',
        'fetch_started',
      ].includes(patchStatus)
    );
  }, [patchStatus]);

  const patchIsFinishedOrStopped = useMemo(() => {
    return (
      patchStatus !== undefined &&
      [
        'fetch_finished',
        'shift_stopped',
      ].includes(patchStatus)
    );
  }, [patchStatus]);

  const engineEventPresent = useMemo(() => {
    return (
      !!patchLogEvents &&
      !!patchLogEvents.length &&
      patchLogEvents.some(
        (event) =>
          event.originData.eventCode.id === 'ENG_DOWN_NORMAL' || event.originData.eventCode.id === 'ENG_UP_NORMAL'
      )
    );
  }, [patchLogEvents]);

  let setChartEventIdToHighlight: Dispatch<SetStateAction<string | null>> | null = null;
  const hoveredEventId = useRef<string | undefined>(undefined);
  const [
    isRecalculatingEngineHours,
    setIsRecalculatingEngineHours,
  ] = useState<boolean>(false);
  const [
    isGeneratingIntermediateEvents,
    setIsGeneratingIntermediateEvents,
  ] = useState<boolean>(false);
  const [
    logsToArchive,
    setLogsToArchive,
  ] = useState<string[]>([]);
  const [
    isUserSelectionModalVisible,
    setUserSelectionModalVisible,
  ] = useState(false);

  const [
    isEventUpdateModalVisible,
    setEventUpdateModalVisible,
  ] = useState(false);
  const [
    eventToUpdate,
    setEventToUpdate,
  ] = useState<(LogEvent & { odometerOffset?: number }) | undefined>();

  useAuthConnectionEffect(() => {
    dispatch(AuditSlice.subscribe());
    dispatch(DotSlice.subscribe());
    dispatch(SubscriptionLockSlice.subscribe());
    dispatch(AlertsSlice.subscribe());
    return () => {
      dispatch(AuditSlice.unsubscribe());
      dispatch(DotSlice.unsubscribe());
      dispatch(SubscriptionLockSlice.unsubscribe());
      dispatch(AlertsSlice.unsubscribe());
    };
  }, []);

  const conflictingAlerts: AlertPayload[] = Object.values(alertsById).filter((alert) => {
    if (alert.companyId !== patch?.companyId) {
      return false;
    }

    if (alert.driverId && alert.driverId !== patch?.driverId) {
      return false;
    }

    const alertTo = alert.to ? moment(alert.to).add(1, 'days').startOf('day') : null;
    const now = moment().startOf('day');

    return alertTo ? now.isBefore(alertTo) : true;
  });
  const alertsHash =
    conflictingAlerts.length > 0
      ? conflictingAlerts
          .map((alert) => alert.id)
          .sort()
          .join('.')
      : null;

  const [
    showAlert,
    setShowAlert,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (alertsHash !== null) {
      setShowAlert(true);
    }
  }, [alertsHash]);

  useAuthConnectionEffect(() => {
    if (manualPatchId) {
      dispatch(ManualPatchSlice.subscribe({ id: manualPatchId }));
      return () => {
        dispatch(ManualPatchSlice.unsubscribe({ id: manualPatchId }));
      };
    }
  }, [manualPatchId]);

  useAuthConnectionEffect((): (() => any) | undefined => {
    if (patch?.companyId) {
      const companyId = patch?.companyId;
      dispatch(DriverSlice.subscribe(companyId));
      return () => dispatch(DriverSlice.unsubscribe(companyId));
    }
  }, [
    patch?.companyId,
  ]);

  useAuthConnectionEffect(() => {
    dispatch(PatchLogEventsSlice.subscribe(manualPatchId));
    return () => {
      manualPatchId && dispatch(PatchLogEventsSlice.unsubscribe(manualPatchId));
    };
  }, [manualPatchId]);

  const manualPatchType = patch?.type;

  useEffect(() => {
    if (manualPatchType) setPatchType(manualPatchType);
  }, [manualPatchType]);

  const patchFixCertifications = patch?.fixCertifications;

  useEffect(() => {
    if (patchFixCertifications) setFixCertifications(patchFixCertifications);
  }, [patchFixCertifications]);

  const patchShiftAmount = patch?.shiftAmount;

  useEffect(() => {
    if (patchShiftAmount != null && patchShiftAmount !== undefined) {
      setShiftAmount(patchShiftAmount);
    }
  }, [patchShiftAmount]);

  useEffect(() => {
    if (role) {
      if (role === 'shifter_day') {
        setPatchType('days');
      }
      if (role === 'shifter_hr') {
        setPatchType('hours');
      }
    }
  }, [
    role,
    patchType,
  ]);

  const [
    lastSelectedAction,
    setLastSelectedAction,
  ] = useState<undefined | { id: string; state: boolean }>();

  const downloadedAlert = useMemo((): undefined | null | [string, string] => {
    return (
      patchLogEvents &&
      R.pipe<PatchLogEventPayload[], LogEvent[], LogEvent[], null | [string, string]>(
        R.map((patchLog) => {
          return patchLog.originData;
        }),
        R.filter((logEvent: LogEvent) => {
          return moment().subtract(9, 'days').isBefore(moment(logEvent.eventTime.timestamp)) && !!logEvent.i;
        }),
        (logs) => {
          const count = logs.length;
          if (count) {
            const timestamps = logs.map((log) => log.eventTime.timestamp);
            const from = moment
              .tz(Math.min(...timestamps), timezones[logs[0].eventTime.logDate.timeZone.id] || 'America/Los_Angeles')
              .format('MMM DD, hh:mm:ss a');
            const to = moment
              .tz(Math.max(...timestamps), timezones[logs[0].eventTime.logDate.timeZone.id] || 'America/Los_Angeles')
              .format('MMM DD, hh:mm:ss a');
            return [
              `⚠️ Warning ⚠️`,
              `The driver has downloaded DOT inspection ${from} - ${to}`,
            ];
          } else {
            return null;
          }
        }
      )(patchLogEvents)
    );
  }, [patchLogEvents]);

  const disablePatchActions = !patch || !patchSubscribed || patchLoading || patchIsBeingFetched;

  const disablePatchLogEventsActions =
    !patchLogEvents ||
    !patchLogEvents.length ||
    !patchLogEventsSubscribed ||
    patchLogEventsLoading === undefined ||
    patchLogEventsLoading === true;

  const patchInputsDisabled = disablePatchActions || patchIsShifted || !isAdminOrShifter || !!patch!.shiftFinishedAt;

  const moveButtonDisabled =
    disablePatchActions || !patchIsFinishedOrStopped || !shiftAmount || disablePatchLogEventsActions;

  const finishButtonDisabled =
    disablePatchActions || !patchIsFinishedOrStopped || !patchLogEventsSubscribed || disablePatchLogEventsActions;

  const removeUnidentifiedButtonDisabled =
    numberOfUnidentifiedEvents === 0 ||
    disablePatchActions ||
    patchIsShifted ||
    !patchIsFinishedOrStopped ||
    disablePatchLogEventsActions;

  const deleteUnidentifiedEvents = async () => {
    if (patch) {
      dispatch(ManualPatchSlice.removeUnidentifiedEvents(patch?.id, patch?.version));
    }
  };

  const archiveButtonDisabled =
    testDrivers === undefined ||
    testDrivers.length === 0 ||
    disablePatchActions ||
    patchIsShifted ||
    !patchIsFinishedOrStopped ||
    !!patch!.shiftFinishedAt ||
    disablePatchLogEventsActions;

  const archiveSelectedButtonDisabled =
    archiveButtonDisabled ||
    selectedNonCertificationEvents.length === 0 ||
    (patchLogEvents || []).some((event) => event.loading);

  const archiveInactivesButtonDisabled =
    testDrivers === undefined ||
    testDrivers.length === 0 ||
    numberOfInactiveEvents === 0 ||
    disablePatchActions ||
    patchIsShifted ||
    !patchIsFinishedOrStopped ||
    !!patch!.shiftFinishedAt ||
    disablePatchLogEventsActions;

  const archiveEvents = (patchLogEvents: PatchLogEventPayload[]) => {
    const logIds = patchLogEvents.map((patchLogEvent) => patchLogEvent.logId);
    if (patch && testDrivers?.length === 1) {
      appDispatch(
        PatchLogEventsSlice.reassignLogsToUser({
          manualPatchId: patch.id,
          logIds: logIds,
          userId: testDrivers[0]._id,
        })
      );
    } else if (patch && testDrivers && testDrivers?.length > 1) {
      setLogsToArchive(logIds);
      setUserSelectionModalVisible(true);
    }
  };

  const confirmArchiveUserSelection = (selectedUser?: string) => {
    setUserSelectionModalVisible(false);
    if (patch && selectedUser) {
      appDispatch(
        PatchLogEventsSlice.reassignLogsToUser({
          manualPatchId: patch.id,
          logIds: logsToArchive,
          userId: selectedUser,
        })
      );
    }
  };

  const cancelArchiveUserSelection = () => setUserSelectionModalVisible(false);

  const recalculateEngineHoursButtonDisabled =
    !patch ||
    !patchSubscribed ||
    (patchLoading && !isRecalculatingEngineHours) ||
    patchIsShifted ||
    !patchIsFinishedOrStopped ||
    disablePatchLogEventsActions ||
    numberOfInactiveEvents > 0 ||
    numberOfUnidentifiedEvents > 0 ||
    !engineEventPresent;

  const generateIntermediatesButtonDisabled =
    !patch ||
    !patchSubscribed ||
    (patchLoading && !isGeneratingIntermediateEvents) ||
    patchIsShifted ||
    !patchIsFinishedOrStopped ||
    disablePatchLogEventsActions ||
    numberOfInactiveEvents > 0 ||
    numberOfUnidentifiedEvents > 0;

  const recalculateEngineHours = async (patch: ManualPatchPayload) => {
    setIsRecalculatingEngineHours(true);
    await dispatch(ManualPatchSlice.recalculateEngineHours(patch.id));
    setIsRecalculatingEngineHours(false);
  };

  const generateIntermediateEvents = async (patch: ManualPatchPayload) => {
    setIsGeneratingIntermediateEvents(true);
    await dispatch(ManualPatchSlice.generateIntermediateEvents(patch.id));
    setIsGeneratingIntermediateEvents(false);
  };

  const recalculateButton = (patch?: ManualPatchPayload) => {
    let tooltipMessage: string | undefined = undefined;
    if (recalculateEngineHoursButtonDisabled && !patchIsShifted && patchIsFinishedOrStopped) {
      tooltipMessage = 'Disabled due to ';
      if (numberOfInactiveEvents > 0) {
        tooltipMessage += 'inactive events';
      } else if (numberOfUnidentifiedEvents > 0) {
        tooltipMessage += 'unidentified events';
      } else if (!engineEventPresent) {
        tooltipMessage += 'absence of engine up and/or shutdown event';
      } else {
        tooltipMessage += 'other reason';
      }
    }

    return patch ? (
      <Col>
        <Popconfirm
          title="The engine hours values will be changed. Are you sure you want to proceed?"
          okText="Yes"
          cancelText="No"
          disabled={recalculateEngineHoursButtonDisabled}
          onConfirm={() => recalculateEngineHours(patch)}
        >
          <span>
            <ConditionalTooltip condition={tooltipMessage !== undefined} title={tooltipMessage}>
              <Button
                type="primary"
                disabled={recalculateEngineHoursButtonDisabled}
                loading={isRecalculatingEngineHours}
              >
                Recalculate engine hours
              </Button>
            </ConditionalTooltip>
          </span>
        </Popconfirm>
      </Col>
    ) : null;
  };

  const generateIntermediatesButton = (patch?: ManualPatchPayload) => {
    let tooltipMessage: string | undefined = undefined;
    if (generateIntermediatesButtonDisabled && !patchIsShifted && patchIsFinishedOrStopped) {
      tooltipMessage = 'Disabled due to ';
      if (numberOfInactiveEvents > 0) {
        tooltipMessage += 'inactive events';
      } else if (numberOfUnidentifiedEvents > 0) {
        tooltipMessage += 'unidentified events';
      } else {
        tooltipMessage += 'other reason';
      }
    }

    return patch ? (
      <Col>
        <Popconfirm
          title="Missing intermediate events will be created. Are you sure you want to proceed?"
          okText="Yes"
          cancelText="No"
          disabled={generateIntermediatesButtonDisabled}
          onConfirm={() => generateIntermediateEvents(patch)}
        >
          <span>
            <ConditionalTooltip condition={tooltipMessage !== undefined} title={tooltipMessage}>
              <Button
                type="primary"
                disabled={generateIntermediatesButtonDisabled}
                loading={isGeneratingIntermediateEvents}
              >
                Generate intermediates
              </Button>
            </ConditionalTooltip>
          </span>
        </Popconfirm>
      </Col>
    ) : null;
  };

  const duplicateEventButtonDisabled =
    disablePatchActions || patchIsShifted || !patchIsFinishedOrStopped || disablePatchLogEventsActions;

  const updateEventButtonDisabled =
    disablePatchActions || patchIsShifted || !patchIsFinishedOrStopped || disablePatchLogEventsActions;

  const updateEvent = (event: LogEvent) => {
    setEventUpdateModalVisible(false);
    if (patch) {
      appDispatch(
        PatchLogEventsSlice.updateLogEvent({
          manualPatchId: patch.id,
          id: event._id,
          event,
        })
      );
    }
  };

  const fetchEvents = async (patchId?: string, patchVersion?: number) => {
    if (patch?.driverId && patch?.to && patch?.from) {
      dispatch(ManualPatchSlice.fetch(patchId || patch?.id, patchVersion || patch?.version));
    }
  };

  const updatePatch = async (patchUpdate: UpdateManualPatchPayload) => {
    if (patch) {
      const result = await appDispatch(
        ManualPatchSlice.update(
          {
            ...patchUpdate,
          },
          patch
        )
      );
      if (result.status !== 'ok') {
        message.error(result?.msg || 'Error');
      }
      if (result.status === 'sync_error') {
        await dispatch(ManualPatchSlice.unsubscribe({ id: manualPatchId }));
        await dispatch(ManualPatchSlice.subscribe({ id: manualPatchId }));
      }
      return result;
    } else {
      return {
        status: 'error',
      };
    }
  };

  const conflictingDot: DotPayload | undefined = Object.values(dotsById).find((dot) => {
    const dotFrom = moment(dot.from).subtract(9, 'days').endOf('day');
    const dotTo = moment(dot.from).endOf('day');
    const patchMoments = [
      moment(patch?.from).startOf('day'),
      moment(patch?.to).endOf('day'),
      moment(patch?.from).subtract(shiftAmount, patchType).startOf('day'),
      moment(patch?.to).subtract(shiftAmount, patchType).endOf('day'),
    ];
    const patchMax = moment.max(patchMoments);
    const patchMin = moment.min(patchMoments);
    return (
      dot &&
      dot.driverId === patch?.driverId &&
      dot.companyId === patch?.companyId &&
      !dot.disabled &&
      (patchMin.isBetween(dotFrom, dotTo) ||
        patchMax.isBetween(dotFrom, dotTo) ||
        dotFrom.isBetween(patchMin, patchMax) ||
        dotTo.isBetween(patchMin, patchMax))
    );
  });

  const conflictingSubscriptionLock: SubscriptionLockPayload | undefined = Object.values(subscriptionLocksById).find(
    (subscriptionLock) => {
      return (
        subscriptionLock &&
        (subscriptionLock.driverId === patch?.driverId || subscriptionLock.driverId == null) &&
        subscriptionLock.companyId === patch?.companyId &&
        !subscriptionLock.disabled
      );
    }
  );

  const conflictingAudit: AuditPayload | undefined = Object.values(auditsById).find((audit) => {
    const auditFrom = moment(audit.from).subtract(1, 'day').endOf('day');
    const auditTo = moment(audit.to).add(1, 'days').startOf('day');
    const patchMoments = [
      moment(patch?.from).startOf('day'),
      moment(patch?.to).endOf('day'),
      moment(patch?.from).subtract(shiftAmount, patchType).startOf('day'),
      moment(patch?.to).subtract(shiftAmount, patchType).endOf('day'),
    ];
    const patchMax = moment.max(patchMoments);
    const patchMin = moment.min(patchMoments);
    return (
      audit &&
      audit.driverId === patch?.driverId &&
      audit.companyId === patch?.companyId &&
      (patchMin.isBetween(auditFrom, auditTo) ||
        patchMax.isBetween(auditFrom, auditTo) ||
        auditFrom.isBetween(patchMin, patchMax) ||
        auditTo.isBetween(patchMin, patchMax))
    );
  });

  const alertMessages = [
    conflictingAudit
      ? [
          'CURRENT DRIVER IS PASSING AUDIT INSPECTION',
          conflictingAudit.message ? conflictingAudit.message : 'Contact supervisor for more details',
        ]
      : null,
    conflictingDot
      ? [
          `CURRENT DRIVER HAD DOT INSPECTION ON ${moment(conflictingDot.from).format('DD.MM.YYYY')}`,
          conflictingDot.message ? conflictingDot.message : 'Contact supervisor for more details',
        ]
      : null,
    conflictingSubscriptionLock
      ? [
          'THIS DRIVER / COMPANY IS SUBSCRIPTION BLOCKED',
          conflictingSubscriptionLock.message
            ? conflictingSubscriptionLock.message
            : 'Contact supervisor for more details',
        ]
      : null,
  ].filter((alert): alert is [string, string] => !!alert);

  const handleVisibleChange = async (visible: boolean) => {
    if (!visible) {
      setVisible(visible);
      return;
    }
    // Determining condition before show the popconfirm.
    if (!downloadedAlert && alertMessages.length === 0) {
      // await shiftData();
      setVisible(false);
    } else {
      setVisible(visible);
    }
  };

  const shiftData = () => {
    if (patch?.id) {
      dispatch(ManualPatchSlice.start(patch?.id, patch?.version, shiftAmount, patchType, fixCertifications));
    }
  };

  const createDuplicatePatch = async () => {
    const clientResourceId = v4();
    const { status, msg, data } = await appDispatch(
      create({
        clientResourceId,
        companyId: patch?.companyId,
        driverId: patch?.driverId,
        type: patchType,
        shiftAmount,
        from: patch?.from,
        to: patch?.to,
      })
    );
    if (status === 'ok') {
      return { id: data?.id, version: 0 };
    } else {
      return { msg };
    }
  };

  const duplicateButton = (
    <Col className="mr-auto">
      <Button
        color="primary"
        onClick={async () => {
          const patchCreated = await createDuplicatePatch();
          if (patchCreated.id) {
            setShiftAmount(0);
            history.push(`/activity/${patchCreated.id}`);
            message.success('Patch has been created');
          } else {
            message.error(patchCreated.msg || 'Error');
          }
        }}
      >
        NEXT LOG
      </Button>
    </Col>
  );

  const finishButton = (patch?: ManualPatchPayload) =>
    patch ? (
      <Dropdown.Button
        disabled={finishButtonDisabled}
        type="primary"
        placement="topRight"
        icon={<DownOutlined />}
        overlay={
          <Menu
            onClick={(e) => {
              if (e.key === 'finish') {
                appDispatch(ManualPatchSlice.remove(patch?.id, patch?.version));
              }
            }}
          >
            <Menu.Item key="finish">FINISH</Menu.Item>
          </Menu>
        }
        onClick={async () => {
          const result = await appDispatch(ManualPatchSlice.remove(patch?.id, patch?.version));
          if (result.status === 'ok') {
            const patchCreated = await createDuplicatePatch();
            if (patchCreated.id) {
              await fetchEvents(patchCreated.id, patchCreated.version);
              setTimeout(() => {
                setShiftAmount(0);
                history.push(`/activity/${patchCreated.id}`);
                message.success('Patch has been created');
              }, 1000);
            }
          }
        }}
      >
        FINISH AND LOAD NEXT
      </Dropdown.Button>
    ) : null;

  const indeterminateSelected = totalSelected > 0 && totalSelected < sortedEvents.length;
  const allSelected = totalSelected === sortedEvents.length && sortedEvents.length > 0;
  const selectAllDisabled =
    !patch ||
    [
      'shift_started',
      'shift_requested',
    ].includes(patchStatus || '') ||
    sortedEvents.length === 0 ||
    sortedEvents.length === numberOfSuccessful;
  const disabled =
    !myAccount ||
    !!patch?.shiftFinishedAt ||
    ![
      'admin',
      'manager',
      'shifter_hr',
      'shifter_day',
      'shifter_hr_day',
      'unrestricted_shifter_hr_day',
    ].includes(myAccount?.role || '') ||
    patchLogEventsLoading;

  const onSelectAllChange = (state: boolean) => {
    if (patch) {
      appDispatch(
        PatchLogEventsSlice.selectAllLogEvents({
          state,
          manualPatchId: patch.id,
        })
      );
    }
  };
  const onSelectChange = (ids: string[], state: boolean) => {
    if (patch) {
      appDispatch(
        PatchLogEventsSlice.selectLogEvents({
          logIds: ids,
          state,
          manualPatchId: patch.id,
        })
      );
    }
  };

  console.timeEnd('RENDER');
  return patch && myAccount ? (
    <Layout className={'accounts-list-container'}>
      <Layout.Content style={{ display: 'flex', flexDirection: 'column' }}>
        <>
          {testDrivers && testDrivers.length > 1 ? (
            <UserSelectionModal
              isVisible={isUserSelectionModalVisible}
              onConfirm={confirmArchiveUserSelection}
              onCancel={cancelArchiveUserSelection}
              availableUsers={testDrivers || []}
            />
          ) : (
            ''
          )}
          <Modal
            className="ant-modal-confirm-warning"
            visible={showAlert}
            cancelButtonProps={{ hidden: true }}
            closable={false}
            onOk={() => setShowAlert(false)}
          >
            <div className="ant-modal-confirm-body-wrapper">
              <div className="ant-modal-confirm-body">
                <ExclamationCircleOutlined />
                <span className="ant-modal-confirm-title">Alert</span>
                <div className="ant-modal-confirm-content">
                  {conflictingAlerts.map((alert) => {
                    return <p key={alert.id}>{alert.message}</p>;
                  })}
                </div>
              </div>
            </div>
          </Modal>
          {eventToUpdate ? (
            <EventUpdateForm
              isVisible={isEventUpdateModalVisible}
              onConfirm={updateEvent}
              onCancel={() => {
                setEventUpdateModalVisible(false);
              }}
              event={eventToUpdate}
            />
          ) : null}
          <Row style={{ width: '100%' }}>
            <Col>
              <SelectStep
                onlyLast8Days={myAccount.role === 'shifter_hr'}
                disabled={patchInputsDisabled}
                patch={patch}
                updatePatch={updatePatch}
                fetchEvents={fetchEvents}
              />
            </Col>
            {patch.driverId && patch.companyId ? (
              <Col style={{ marginLeft: 'auto', float: 'right' }}>
                <Tooltip
                  placement={'topRight'}
                  title={driverNote ? `Checked by ${driverNote?.ownerUsername}` : 'No record available'}
                >
                  <DatePicker
                    value={driverNote?.freeMonth ? moment(driverNote?.freeMonth, 'MMMM') : null}
                    onChange={(value) => {
                      if (patch.driverId && patch.companyId)
                        if (value) {
                          dispatch(
                            DriverNoteSlice.upsert({
                              freeMonth: value.format('MMMM'),
                              driverId: patch.driverId,
                              companyId: patch.companyId,
                            })
                          );
                        } else {
                          dispatch(
                            DriverNoteSlice.deleteNote({
                              driverId: patch.driverId,
                            })
                          );
                        }
                    }}
                    picker="month"
                    format={'MMMM'}
                  />
                </Tooltip>
              </Col>
            ) : null}
          </Row>
          {!!downloadedAlert && (
            <Alert
              style={{ marginTop: 16 }}
              message={
                <div>
                  <h3>{downloadedAlert[0]}</h3>
                  <b>{downloadedAlert[1]}</b>
                </div>
              }
              type="error"
            />
          )}

          {alertMessages.map(
            (
              [
                title,
                description,
              ],
              index
            ) => (
              <Alert
                key={index}
                style={{ marginTop: 16 }}
                message={
                  <div>
                    <h3>{title}</h3>
                    <b>{description}</b>
                  </div>
                }
                type="error"
              />
            )
          )}
          {chartEvents && chartEvents?.length > 0 && (
            <Collapse destroyInactivePanel={true} style={{ marginTop: '8px' }}>
              <Panel header="Log chart" key="1">
                <div className="chart">
                  <DriverLogsChart
                    events={chartEvents}
                    onMount={(setHighlightedEventId) => (setChartEventIdToHighlight = setHighlightedEventId)}
                    onEventHover={(hoveredEvent) => {
                      hoveredEventId.current = hoveredEvent?.id;
                      if (hoveredEvent) {
                        const row = sortedEvents.findIndex((event) => event.id === hoveredEvent.id);
                        list.current?.scrollToRow(row);
                        list.current?.forceUpdateGrid();
                      }
                    }}
                  />
                </div>
              </Panel>
            </Collapse>
          )}
          {patchLogEvents && patchLogEvents?.length > 0 ? (
            <Tooltip
              placement={'bottom'}
              overlayStyle={{ maxWidth: '100vh' }}
              getPopupContainer={getContainer}
              getTooltipContainer={getContainer}
              title={`${numberOfErrors} errors | ${
                numberOfSuccessful - numberOfRolledBack
              } succeed | ${numberOfRolledBack} rolled back | ${
                totalSelected - (numberOfSuccessful + numberOfErrors)
              } left | ignored ${totalNumber - totalSelected}`}
            >
              <Progress
                format={(percent, successPercent) => `${numberOfSuccessful - numberOfRolledBack}/${totalSelected}`}
                style={{ marginTop: 8, paddingRight: 30 }}
                status={numberOfErrors ? 'exception' : 'normal'}
                success={{
                  percent: Math.round(((numberOfSuccessful - numberOfRolledBack) / totalNumber) * 100),
                }}
                percent={Math.round((totalSelected / totalNumber) * 100)}
              />
            </Tooltip>
          ) : null}
          <>
            <div className="rtl-table-row rtl-table-header">
              <div className="select">
                <Checkbox
                  indeterminate={indeterminateSelected}
                  checked={allSelected}
                  disabled={selectAllDisabled || disabled}
                  onChange={() => {
                    onSelectAllChange(indeterminateSelected || !allSelected);
                  }}
                />
              </div>
              <div className="lg">TIME</div>
              <div className="sm">DURATION</div>
              <div className="xxl">EVENT</div>
              <div className="md">STATUS</div>
              <div className="xxl">LOCATION</div>
              <div className="md">ORIGIN</div>
              <div className="sm">ODOMETER</div>
              <div className="md">ENGINE HOURS</div>
              {/*<div>_ID</div>*/}
              <div className="xxl">NOTES</div>
              <div className="xs">ID</div>
              <div className="lg">DRIVER</div>
              <div className="sm">STATUS</div>
              <div>Actions</div>
            </div>
            <div
              className="rtl-table"
              onMouseLeave={() => setChartEventIdToHighlight && setChartEventIdToHighlight(null)}
            >
              <AutoSizer>
                {({ width, height }) => (
                  <Spin
                    style={{
                      width,
                      height,
                    }}
                    spinning={
                      [
                        'fetch_started',
                        'fetch_requested',
                      ].includes(patchStatus || '') || !patchLogEventsSubscribed
                    }
                  >
                    <List
                      scrollToIndex={
                        [
                          'shift_requested',
                          'shift_started',
                        ].includes(patchStatus || '') && lastFinishedIndex >= 0
                          ? lastFinishedIndex
                          : undefined
                      }
                      className="rtl-list"
                      ref={list}
                      height={height}
                      overscanRowCount={20}
                      // noRowsRenderer={this._noRowsRenderer}
                      rowCount={(sortedEvents || []).length}
                      rowHeight={40}
                      width={width}
                      rowRenderer={({ index, style }) => {
                        const patchLogEvent = (sortedEvents || [])[index];
                        const logEvent = patchLogEvent.originData;
                        const user = (driversById || {})[logEvent.userId];
                        let status;
                        if (patchLogEvent.failedAt) {
                          status = <Tag color={'error'}>ERROR</Tag>;
                        } else if (patchLogEvent.rollbackAt) {
                          status = <Tag color={'warning'}>ROLLED BACK</Tag>;
                        } else if (patchLogEvent.committedAt) {
                          status = <Tag color={'success'}>DONE</Tag>;
                        } else if (!patchLogEvent.selected) {
                          status = <Tag color={'default'}>IGNORED</Tag>;
                        } else {
                          status = <Tag color={'processing'}>PENDING</Tag>;
                        }
                        const selected = patchLogEvent.selected || isRestricted(fixCertifications, patchLogEvent);

                        return (
                          <ConditionalTooltip
                            key={patchLogEvent.id}
                            condition={patchLogEvent.error !== undefined || patchLogEvent.warning !== undefined}
                            title={[
                              patchLogEvent.error,
                              patchLogEvent.warning,
                            ]
                              .filter((m) => Boolean(m))
                              .join('\n')}
                            placement="topLeft"
                            // @ts-ignore
                            align={{ targetOffset: ['-20%'] }}
                            overlayStyle={{ whiteSpace: 'pre-line' }}
                          >
                            <div
                              className={`rtl-table-row ${selected ? 'rtl-table-row-selected' : ''} ${
                                patchLogEvent.id === hoveredEventId.current ? 'highlighted' : ''
                              } ${
                                patchLogEvent.error
                                  ? 'rtl-table-row-has-error'
                                  : patchLogEvent.warning
                                  ? 'rtl-table-row-has-warning'
                                  : ''
                              } ${patchLogEvent.loading ? 'rtl-table-row-loading' : ''}`}
                              style={{
                                ...style,
                                borderBottom: '1px solid #ededed',
                              }}
                              onMouseEnter={() =>
                                setChartEventIdToHighlight && setChartEventIdToHighlight(patchLogEvent.id)
                              }
                            >
                              <div className="select">
                                <Checkbox
                                  checked={selected}
                                  disabled={
                                    selectAllDisabled ||
                                    ['done'].includes(getEventStatus(patchLogEvent)) ||
                                    disabled ||
                                    isRestricted(fixCertifications, patchLogEvent) ||
                                    patchLogEvent.loading
                                  }
                                  // disabled={this.state.disabled}
                                  onChange={(event) => {
                                    if (!patchLogEvent.loading) {
                                      if (event.nativeEvent.shiftKey && lastSelectedAction) {
                                        const notFinishedEvents = sortedEvents.filter((event) => {
                                          return !event.committedAt;
                                        });
                                        const index = notFinishedEvents.findIndex(
                                          ({ id }) => id === lastSelectedAction.id
                                        );
                                        const newIndex = notFinishedEvents.findIndex(
                                          ({ id }) => id === patchLogEvent.id
                                        );
                                        const ids = R.pluck(
                                          'id',
                                          newIndex > index
                                            ? R.slice(index, newIndex + 1, sortedEvents)
                                            : R.slice(newIndex, index + 1, sortedEvents)
                                        );
                                        onSelectChange(ids, !patchLogEvent.selected);
                                      } else {
                                        onSelectChange([patchLogEvent.id], !patchLogEvent.selected);
                                      }
                                      setLastSelectedAction({
                                        id: patchLogEvent.id,
                                        state: !patchLogEvent.selected,
                                      });
                                    }
                                  }}
                                />
                              </div>
                              <div className="lg">
                                {moment
                                  .tz(
                                    logEvent.eventTime.timestamp,
                                    timezones[logEvent.eventTime.logDate.timeZone.id] || 'America/Los_Angeles'
                                  )
                                  .format('MMM DD, hh:mm:ss a')}
                              </div>
                              <div className="sm">
                                {patchLogEvent.duration !== undefined ? convertMsToTime(patchLogEvent.duration) : ''}
                              </div>
                              <div className="xxl">{getEventLabel(logEvent)}</div>
                              <div className="md">{statusLabels[logEvent.recordStatus?.id] || 'ACTIVE (EMPTY)'}</div>
                              <div className="xxl">{logEvent.location?.calculatedLocation}</div>
                              <div className="md">
                                {originLabels[logEvent.recordOrigin?.id] || logEvent.recordOrigin?.id}
                              </div>
                              <div className="sm">
                                {logEvent.totalVehicleMiles &&
                                  logEvent.totalVehicleMiles + (patchLogEvent?.odometerOffset || 0)}
                              </div>
                              <div className="md">{logEvent.totalEngineHours}</div>
                              {/*<div>{logEvent._id}</div>*/}
                              <div className="xxl">{logEvent.eventComment}</div>
                              <div className="xs">{logEvent.seqId && parseInt(logEvent.seqId, 16)}</div>
                              <div className="lg">
                                {user?.firstName} {user?.lastName}
                              </div>
                              <div className="sm">{status}</div>
                              <div>
                                <Space>
                                  <Tooltip title="Duplicate the event">
                                    <Button
                                      type="primary"
                                      shape="circle"
                                      icon={<CopyOutlined />}
                                      size="small"
                                      disabled={duplicateEventButtonDisabled || patchLogEvent.loading}
                                      onClick={() =>
                                        appDispatch(
                                          PatchLogEventsSlice.duplicateLogEvent({
                                            manualPatchId,
                                            logId: patchLogEvent.id,
                                          })
                                        )
                                      }
                                    />
                                  </Tooltip>
                                  <Tooltip title="Edit the event">
                                    <Button
                                      type="primary"
                                      danger
                                      shape="circle"
                                      icon={<EditOutlined />}
                                      size="small"
                                      disabled={updateEventButtonDisabled || patchLogEvent.loading}
                                      onClick={() => {
                                        setEventToUpdate({
                                          ...patchLogEvent.originData,
                                          odometerOffset: patchLogEvent.odometerOffset,
                                        });
                                        setEventUpdateModalVisible(true);
                                      }}
                                    />
                                  </Tooltip>
                                  <Tooltip
                                    title={
                                      testDrivers && testDrivers.length === 0
                                        ? 'No Test Driver found'
                                        : 'Assign to Test Driver'
                                    }
                                  >
                                    <Button
                                      type="primary"
                                      danger
                                      shape="circle"
                                      icon={<UserSwitchOutlined />}
                                      size="small"
                                      disabled={archiveButtonDisabled || patchLogEvent.loading}
                                      onClick={(event) => {
                                        archiveEvents([patchLogEvent]);
                                      }}
                                    />
                                  </Tooltip>
                                </Space>
                              </div>
                            </div>
                          </ConditionalTooltip>
                        );
                      }}
                    />
                  </Spin>
                )}
              </AutoSizer>
            </div>
            <Row className="mt-3" justify="end" gutter={16} align="middle">
              <Col className="mr-auto">
                <Dropdown
                  placement="topLeft"
                  overlay={
                    <Menu
                      onClick={async (e) => {
                        if (e.key === 'duplicate') {
                          const patchCreated = await createDuplicatePatch();
                          if (patchCreated.id) {
                            setShiftAmount(0);
                            history.push(`/activity/${patchCreated.id}`);
                            message.success('Patch has been created');
                          } else {
                            message.error(patchCreated.msg || 'Error');
                          }
                        } else if (e.key === 'fix_certification') {
                          setFixCertifications(R.not);
                        }
                      }}
                    >
                      <Menu.Item key="duplicate">NEXT LOG</Menu.Item>
                      {!patch.shiftFinishedAt &&
                      [
                        'unrestricted_shifter_hr_day',
                        'shifter_hr_day',
                        'manager',
                        'admin',
                      ].includes(myAccount.role || '') ? (
                        <Menu.Item key="fix_certification" disabled={patchInputsDisabled}>
                          <Tooltip
                            mouseEnterDelay={1}
                            getPopupContainer={getContainer}
                            getTooltipContainer={getContainer}
                            title={`Fix certifications? (last 9 days)`}
                          >
                            <Space>
                              <Switch checked={fixCertifications} />
                              CERTIFICATIONS
                            </Space>
                          </Tooltip>
                        </Menu.Item>
                      ) : null}
                    </Menu>
                  }
                >
                  <Button>
                    <EllipsisOutlined />
                  </Button>
                </Dropdown>
              </Col>
              {patch.shiftFinishedAt &&
              [
                'admin',
                'manager',
              ].includes(myAccount.role || '') ? (
                <>
                  <Col>{`Moved on ${patch.shiftAmount} ${patch.type} `.toUpperCase()}</Col>
                  {patchStatus === 'shift_rollback_started' ? (
                    <Col>
                      <Button
                        color="primary"
                        onClick={() => {
                          appDispatch(ManualPatchSlice.stopRollback(patch?.id, patch?.version));
                        }}
                      >
                        STOP ROLLBACK
                      </Button>
                    </Col>
                  ) : (
                    <>
                      {numberOfRolledBack > 0 ? (
                        <Col>
                          <Button
                            color="primary"
                            onClick={() => {
                              appDispatch(ManualPatchSlice.startRollback(patch?.id, patch?.version, true));
                            }}
                          >
                            RESTART ROLLBACK
                          </Button>
                        </Col>
                      ) : null}

                      {numberOfRolledBack !== numberOfSuccessful ? (
                        <Col>
                          <Button
                            onClick={() => {
                              appDispatch(ManualPatchSlice.startRollback(patch?.id, patch?.version, false));
                            }}
                            color="primary"
                            type="primary"
                          >
                            {numberOfRolledBack > 0 ? 'CONTINUE ROLLBACK' : 'ROLLBACK'}
                          </Button>
                        </Col>
                      ) : null}
                    </>
                  )}
                </>
              ) : null}
              {!patch.shiftFinishedAt &&
              [
                'admin',
                'manager',
                'shifter_hr',
                'shifter_day',
                'shifter_hr_day',
                'unrestricted_shifter_hr_day',
              ].includes(myAccount.role || '') ? (
                <>
                  <Col>
                    <ConditionalTooltip
                      condition={!patchIsShifted && patchIsFinishedOrStopped}
                      title="Reassign selected to Test Driver"
                    >
                      <Button
                        type="primary"
                        disabled={archiveSelectedButtonDisabled}
                        onClick={() => {
                          confirm({
                            title: 'Confirm bulk reassign',
                            icon: <ExclamationCircleOutlined />,
                            content: `Are you sure you want to reassign ${selectedNonCertificationEvents.length} selected events to the test driver?`,
                            onOk() {
                              archiveEvents(selectedNonCertificationEvents);
                            },
                            autoFocusButton: null,
                          });
                        }}
                        danger
                      >
                        Reassign{' '}
                        {selectedNonCertificationEvents.length > 0 ? ` ${selectedNonCertificationEvents.length} ` : ''}
                        selected
                      </Button>
                    </ConditionalTooltip>
                  </Col>
                  <Col>
                    <ConditionalTooltip
                      condition={!patchIsShifted && patchIsFinishedOrStopped}
                      title="Reassign inactive to Test Driver"
                    >
                      <Button
                        type="primary"
                        disabled={archiveInactivesButtonDisabled}
                        onClick={() => {
                          archiveEvents(
                            sortedEvents.filter((event) => event.originData.recordStatus?.id.startsWith('INACTIVE_'))
                          );
                        }}
                      >
                        Reassign {numberOfInactiveEvents > 0 ? ` ${numberOfInactiveEvents} ` : ''} inactive
                      </Button>
                    </ConditionalTooltip>
                  </Col>
                  <Col
                    style={{
                      color: numberOfUnidentifiedEvents > 0 ? 'red' : 'initial',
                    }}
                  >
                    <ConditionalTooltip
                      condition={!patchIsShifted && patchIsFinishedOrStopped}
                      title="Delete unidentified events"
                    >
                      <Button
                        type="primary"
                        disabled={removeUnidentifiedButtonDisabled}
                        onClick={deleteUnidentifiedEvents}
                      >
                        Delete {numberOfUnidentifiedEvents > 0 ? ` ${numberOfUnidentifiedEvents} ` : ''} unidentified
                      </Button>
                    </ConditionalTooltip>
                  </Col>
                  {generateIntermediatesButton(patch)}
                  {recalculateButton(patch)}
                  <Col>
                    <Button
                      type="primary"
                      disabled={!hasErrorOrWarning}
                      onClick={() => {
                        list.current?.scrollToRow(eventsWithErrorOrWarnings[currentEventWithErrorIdx--]);
                        if (currentEventWithErrorIdx < 0) {
                          currentEventWithErrorIdx = eventsWithErrorOrWarnings.length - 1;
                        }
                      }}
                    >
                      Scroll from last error
                    </Button>
                  </Col>
                  <Col>
                    <Tooltip
                      mouseEnterDelay={1}
                      getPopupContainer={getContainer}
                      getTooltipContainer={getContainer}
                      title={`Specify shift back in ${patchType}`}
                    >
                      <InputNumber
                        disabled={patchInputsDisabled}
                        value={shiftAmount}
                        precision={0}
                        onChange={(val: number) => setShiftAmount(val)}
                        placeholder={`${patchType === 'hours' ? 'HH' : 'DD'}`}
                      />
                    </Tooltip>
                  </Col>
                  <Col>
                    <Radio.Group
                      defaultValue={manualPatchType}
                      disabled={patchInputsDisabled}
                      onChange={(e) => {
                        setPatchType(e.target.value);
                      }}
                      buttonStyle="solid"
                    >
                      <Radio.Button value="days">Days</Radio.Button>
                      {[
                        'unrestricted_shifter_hr_day',
                        'shifter_hr_day',
                        'manager',
                        'admin',
                      ].includes(myAccount.role || '') ? (
                        <Radio.Button value="hours">Hours</Radio.Button>
                      ) : null}
                      <Radio.Button value="minutes">Minutes</Radio.Button>
                    </Radio.Group>
                  </Col>
                  <Col>
                    <Button
                      disabled={
                        ![
                          'shift_started',
                          'shift_requested',
                        ].includes(patchStatus || '')
                      }
                      color="primary"
                      onClick={() => {
                        appDispatch(ManualPatchSlice.stop(patch?.id, patch?.version));
                      }}
                    >
                      STOP
                    </Button>
                  </Col>
                  {numberOfSuccessful !== totalSelected ? (
                    <Col>
                      <Popconfirm
                        getPopupContainer={() => document.body}
                        getTooltipContainer={() => document.body}
                        placement="top"
                        visible={visible}
                        onVisibleChange={handleVisibleChange}
                        title={downloadedAlert?.[1] || alertMessages[0]?.[0]}
                        onConfirm={shiftData}
                        okText="Ok"
                        disabled={moveButtonDisabled}
                        cancelText="Cancel"
                      >
                        <Button
                          onClick={!downloadedAlert && alertMessages.length === 0 ? shiftData : undefined}
                          disabled={moveButtonDisabled}
                          color="primary"
                          type="primary"
                        >
                          Move on ({shiftAmount}{' '}
                          {patchType === 'minutes'
                            ? shiftAmount > 1
                              ? 'minutes'
                              : 'minute'
                            : patchType === 'hours'
                            ? shiftAmount > 1
                              ? 'hours'
                              : 'hour'
                            : shiftAmount > 1
                            ? 'days'
                            : 'day'}
                          )
                        </Button>
                      </Popconfirm>
                    </Col>
                  ) : null}
                  {patch.shiftStoppedAt && <Col>{finishButton(patch)}</Col>}
                </>
              ) : null}
            </Row>
          </>
        </>
      </Layout.Content>
    </Layout>
  ) : null;
};
export default ManualPatchDetails;
