import { CompanyPayload } from 'interfaces';
import { useSelector } from 'react-redux';
import { createSubscriptiveSlice } from 'slices/subscriptive';

const {
  select,
  selectDictionary,
  unsubscribe,
  reducer,
  reconnect,
  onPublish,
  subscribe,
  initialState,
  selectResourceList,
  slice,
} = createSubscriptiveSlice({
  // @ts-expect-error _id is not part of BaseModal
  idProp: '_id',
  volatile: true,
  payloadType: CompanyPayload,
  name: 'companies',
  deletedFilterFn(resource): boolean {
    return resource.deletedAt != null;
  },
  reducers: {},
});

export { unsubscribe, reducer, reconnect, onPublish, subscribe, initialState };

export const useCompanies = () => {
  const { loading: companiesLoading, subscribed: companiesSubscribed } = useSelector(select);
  const companies = useSelector(selectResourceList) as CompanyPayload[];
  const companiesById = useSelector(selectDictionary) as Record<string, CompanyPayload>;

  return {
    companies,
    companiesById,
    companiesLoading,
    companiesSubscribed,
  };
};

export default slice.reducer;
