import { InfoCircleOutlined, PoweroffOutlined, RollbackOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm, Space, Table, Tag, Tooltip } from 'antd';
import {
  AccountPayload,
  manualPatchColorByStatus,
  ManualPatchPayload,
  ManualPatchStatus,
  manualPatchTitleByStatus,
} from 'interfaces';
import moment from 'moment-timezone';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { remove, startRollback } from 'slices/manualPatch';
import { useAppDispatch } from 'store/store';
import { timezones } from 'utils/timezone';

const ManualPatchesTable = ({
  accountsById,
  manualPatches,
  timezone,
  account,
  loading,
}: {
  loading?: boolean;
  timezone: keyof typeof timezones;
  manualPatches: ManualPatchPayload[];
  accountsById?: Record<string, AccountPayload> | undefined;
  account: AccountPayload | null;
}) => {
  const history = useHistory();
  const appDispatch = useAppDispatch();
  return (
    <Table
      loading={loading}
      pagination={{
        pageSizeOptions: [
          '10',
          '50',
          '100',
          '250',
          '500',
          '1000',
        ],
        showSizeChanger: true,
      }}
      dataSource={manualPatches}
    >
      <Table.Column
        title="#"
        dataIndex="ownerId"
        key="ownerId"
        render={(ownerId: string, data: ManualPatchPayload) => {
          return accountsById &&
            account &&
            [
              'admin',
              'viewer',
              'manager',
            ].includes(account.role)
            ? accountsById[ownerId]?.username
            : null;
        }}
      />
      {accountsById &&
      account &&
      [
        'admin',
        'viewer',
        'manager',
      ].includes(account.role) ? (
        <Table.Column
          title="USER"
          dataIndex="ownerId"
          key="ownerId"
          render={(ownerId: string, data: ManualPatchPayload) => {
            return accountsById[ownerId]?.name || accountsById[ownerId]?.username;
          }}
        />
      ) : null}
      <Table.Column
        title="COMPANY"
        dataIndex="companyId"
        key="companyId"
        render={(companyId: string, record: ManualPatchPayload) => {
          return companyId ? <Tooltip title={companyId}>{record.companyName}</Tooltip> : '-';
        }}
      />
      <Table.Column
        title="DRIVER"
        dataIndex="driverName"
        key="driverName"
        render={(driverName: string) => {
          return driverName ? <Tooltip title={driverName}>{driverName}</Tooltip> : '-';
        }}
      />
      <Table.Column
        title="PERIOD"
        dataIndex="from"
        key="from"
        render={(_, manualPatch: ManualPatchPayload) => {
          return `${manualPatch.to ? moment(manualPatch.from).format('DD.MM.YYYY') : 'N/A'}-${
            manualPatch.to ? moment(manualPatch.to).format('DD.MM.YYYY') : 'N/A'
          }`;
        }}
      />
      <Table.Column
        title="SHIFT (DAYS/HRS)"
        dataIndex="shiftAmount"
        key="shiftAmount"
        render={(shiftAmount: number, record: any) => {
          return shiftAmount ? `${shiftAmount} ${record.type}`.toUpperCase() : '-';
        }}
      />
      <Table.Column
        title="CREATED (DATE/TIME)"
        dataIndex="createdAt"
        key="createdAt"
        render={(createdAt: string) => {
          return createdAt ? moment(createdAt).tz(timezones[timezone]).format('DD.MM.YYYY HH:mm') : '-';
        }}
      />
      <Table.Column
        title="STATUS"
        dataIndex="status"
        key="status"
        filters={[
          {
            text: 'DELETED',
            value: 'deleted',
          },
          // deleted: "DELETED",
          {
            text: 'ROLLING BACK STARTED',
            value: 'shift_rollback_started',
          },
          // shift_rollback_started: "ROLLING BACK",
          {
            text: 'ROLLED BACK STOPPED',
            value: 'shift_rollback_stopped',
          },
          // shift_rollback_stopped: "ROLLED BACK",
          {
            text: 'SHIFT FINISHED',
            value: 'shift_finished',
          },
          // shift_finished: "SHIFT FINISHED",
          {
            text: 'PROCESSING STOPPED',
            value: 'shift_stopped',
          },
          // shift_stopped: "PROCESSING",
          {
            text: 'PROCESSING STARTED',
            value: 'shift_started',
          },
          // shift_started: "PROCESSING",
          {
            text: 'PROCESSING STOPPED',
            value: 'shift_requested',
          },
          // shift_requested: "PROCESSING",
          {
            text: 'PENDING FINISHED',
            value: 'fetch_finished',
          },
          // fetch_finished: "PENDING",
          {
            text: 'FETCH PENDING STARTED',
            value: 'fetch_started',
          },
          // fetch_started: "PENDING",
          {
            text: 'PENDING REQUESTED',
            value: 'fetch_requested',
          },
          // fetch_requested: "PENDING",
          {
            text: 'CREATED',
            value: 'created',
          },
          // created: "CREATED",
        ]}
        onFilter={(value: any, record: any) => record.status.indexOf(value) === 0}
        render={(status: ManualPatchStatus) => {
          return status && <Tag color={manualPatchColorByStatus[status]}>{manualPatchTitleByStatus[status]}</Tag>;
        }}
      />

      <Table.Column
        dataIndex="id"
        key="id"
        render={(_, manualPatch: ManualPatchPayload) => {
          return (
            <Space size="small">
              <Tooltip title="Info" placement={'bottom'}>
                <Button
                  href={`/activity/${manualPatch.id}`}
                  shape="circle"
                  icon={<InfoCircleOutlined className={'icon-info icon-medium'} />}
                />
              </Tooltip>
              {!!manualPatch.shiftFinishedAt &&
              [
                'admin',
                'manager',
              ].includes(account?.role || '') ? (
                <Tooltip title="Rollback" placement={'bottom'}>
                  <Popconfirm
                    title={'ROLLBACK THIS LOG ?'}
                    onConfirm={async () => {
                      const response = await appDispatch(startRollback(manualPatch.id, manualPatch.version, true));
                      if (response.status == 'ok') {
                        message.success(`LOG ROLLING BACK`);
                      } else {
                        message.error('Cannot rollback patch');
                      }
                    }}
                    okText="YES"
                    cancelText="NO"
                  >
                    <Button shape="circle" icon={<RollbackOutlined className={'icon-info icon-medium'} />} />
                  </Popconfirm>
                </Tooltip>
              ) : null}
              {!manualPatch.shiftFinishedAt && account?.role === 'admin' ? (
                <Tooltip title="Finish patch" placement={'bottom'}>
                  <Popconfirm
                    title={'FINISH THIS LOG?'}
                    onConfirm={async () => {
                      const response = await appDispatch(remove(manualPatch.id, manualPatch.version));
                      if (response.status == 'ok') {
                        message.success(`LOG - FINISHED`);
                      } else {
                        message.error('Cannot finish patch');
                      }
                    }}
                    okText="YES"
                    cancelText="NO"
                  >
                    <Button shape="circle" danger icon={<PoweroffOutlined className={'icon-medium'} />} />
                  </Popconfirm>
                </Tooltip>
              ) : null}
            </Space>
          );
        }}
      />
    </Table>
  );
};

export default ManualPatchesTable;
