import { LogEvent, PatchLogEventPayload } from 'interfaces';
import * as r from 'ramda';
import { useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { ResponsePayload } from 'slices/subscriptive';
import { createRelativeSubscriptiveSlice } from 'slices/subscriptive/relative';
import { Socket } from 'socket.io-client';
import { RootState } from 'store/reducer';
import { emitAsyncOrdered } from 'utils/socket';
import { setError } from './errors';

const {
  select,
  selectChildResourceList,
  unsubscribe,
  reducer,
  reconnect,
  onPublish,
  onPartial,
  setLoadingResources,
  subscribe,
  slice,
  resourceId,
} = createRelativeSubscriptiveSlice({
  name: 'patchLogEvents',
  parentName: 'manualPatches',
  parentSingleName: 'manualPatch',
  payloadType: PatchLogEventPayload,
  deletedFilterFn(resource): boolean {
    return resource.deletedAt != null;
  },
  reducers: {},
  idProp: 'id',
});

const { setLoading } = slice.actions;

export class SelectPatchLogEventsPayload {
  manualPatchId: string;

  logIds: string[];

  state: boolean;
}

const selectLogEvents =
  ({ manualPatchId, state, logIds }: SelectPatchLogEventsPayload) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<ResponsePayload<null | undefined>> => {
    const socket = getSocket();
    await dispatch(
      setLoadingResources({
        parentId: manualPatchId,
        ids: logIds,
        state: true,
      })
    );

    const response = await emitAsyncOrdered<ResponsePayload<null | undefined>>(
      socket,
      resourceId,
      `manualPatch/patchLogEvents:select`,
      { manualPatchId, state, logIds }
    );

    if (response.status !== 'ok') {
      await dispatch(
        setLoadingResources({
          parentId: manualPatchId,
          ids: logIds,
          state: false,
        })
      );
      dispatch(setError({ status: response.status, msg: response.msg }));
    }

    return response;
  };
export class SelectAllPayload {
  manualPatchId: string;

  state: boolean;
}

const selectAllLogEvents =
  ({ manualPatchId, state }: SelectAllPayload) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<ResponsePayload<null | undefined>> => {
    const account = getState().myAccount.resource;
    const socket = getSocket();
    if (!!account) {
      await dispatch(setLoading({ parentId: manualPatchId, loading: true }));
      const response = await emitAsyncOrdered<ResponsePayload<null | undefined>>(
        socket,
        resourceId,
        `manualPatch/patchLogEvents:selectAll`,
        { manualPatchId, state }
      );
      await dispatch(setLoading({ parentId: manualPatchId, loading: false }));

      if (response.status !== 'ok') {
        dispatch(setError({ status: response.status, msg: response.msg }));
      }

      return response;
    } else {
      dispatch(setError({ status: 'unauthenticated' }));
      return {
        status: 'unauthenticated',
      };
    }
  };

export class ReassignPatchLogEventsPayload {
  manualPatchId: string;
  logIds: string[];
  userId: string;
}

const reassignLogsToUser =
  ({ manualPatchId, logIds, userId }: ReassignPatchLogEventsPayload) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<ResponsePayload<null | undefined>> => {
    const socket = getSocket();
    await dispatch(
      setLoadingResources({
        parentId: manualPatchId,
        ids: logIds,
        state: true,
      })
    );

    const response = await emitAsyncOrdered<ResponsePayload<null | undefined>>(
      socket,
      resourceId,
      `manualPatch/patchLogEvents:reassign`,
      { manualPatchId, logIds, userId },
      false
    );

    if (response.status !== 'ok') {
      await dispatch(
        setLoadingResources({
          parentId: manualPatchId,
          ids: logIds,
          state: false,
        })
      );
      dispatch(setError({ status: response.status, msg: response.msg }));
    }

    return response;
  };

export class UpdateLogEventPayload {
  manualPatchId: string;
  id: string;
  event: LogEvent;
}

const updateLogEvent =
  ({ manualPatchId, id, event }: UpdateLogEventPayload) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<ResponsePayload<null | undefined>> => {
    const socket = getSocket();
    await dispatch(
      setLoadingResources({
        parentId: manualPatchId,
        ids: [id],
        state: true,
      })
    );

    const response = await emitAsyncOrdered<ResponsePayload<null | undefined>>(
      socket,
      resourceId,
      `manualPatch/patchLogEvents:update`,
      { manualPatchId, id, event },
      false
    );

    if (response.status !== 'ok') {
      await dispatch(
        setLoadingResources({
          parentId: manualPatchId,
          ids: [id],
          state: false,
        })
      );
      dispatch(setError({ status: response.status, msg: response.msg }));
    }

    return response;
  };

export class DuplicateLogEventPayload {
  manualPatchId: string;
  logId: string;
}

const duplicateLogEvent =
  ({ manualPatchId, logId }: DuplicateLogEventPayload) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<ResponsePayload<null | undefined>> => {
    const socket = getSocket();
    await dispatch(
      setLoadingResources({
        parentId: manualPatchId,
        ids: [logId],
        state: true,
      })
    );

    const response = await emitAsyncOrdered<ResponsePayload<null | undefined>>(
      socket,
      resourceId,
      `manualPatch/patchLogEvents:duplicate`,
      { manualPatchId, logId },
      false
    );

    if (response.status !== 'ok') {
      await dispatch(
        setLoadingResources({
          parentId: manualPatchId,
          ids: [logId],
          state: false,
        })
      );
      dispatch(setError({ status: response.status, msg: response.msg }));
    }

    return response;
  };

export default slice.reducer;

export {
  selectLogEvents,
  selectAllLogEvents,
  reassignLogsToUser,
  updateLogEvent,
  duplicateLogEvent,
  select,
  selectChildResourceList,
  unsubscribe,
  reducer,
  reconnect,
  onPublish,
  onPartial,
  subscribe,
  slice,
};

export const usePatchLogEvents = (manualPatchId: string | null) => {
  const {
    resourceDictionary: patchLogEventsById,
    loading: patchLogEventsLoading,
    subscribed: patchLogEventsSubscribed,
  } = useSelector(r.partial(select, [manualPatchId])) || {};

  const patchLogEvents = useSelector(r.partial(selectChildResourceList, [manualPatchId])) as
    | PatchLogEventPayload[]
    | undefined;

  return {
    patchLogEvents: patchLogEvents as PatchLogEventPayload[] | undefined,
    patchLogEventsById: patchLogEventsById as Record<string, PatchLogEventPayload> | undefined,
    patchLogEventsLoading,
    patchLogEventsSubscribed,
  };
};
