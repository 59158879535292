import {
  Button,
  Descriptions,
  Input,
  Layout,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Radio,
  Spin,
  Tag,
  Typography,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import AccountForm from 'components/AccountForm';
import ManualPatchesTable from 'components/ManualPatchesTable';
import { useAuthConnectionEffect } from 'hooks/useAuthConnectionEffect';
import { accountColorByStatus, accountRoleLabels } from 'interfaces';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as AccountSlice from 'slices/account';
import { deleteAccount, disable, enable, logout, update, UpdateAccountPayload, useAccount } from 'slices/account';
import * as AccountManualPatchSlice from 'slices/accountManualPatches';
import { useAccountManualPatches } from 'slices/accountManualPatches';
import { useMyAccount } from 'slices/myAccount';
import { useAppDispatch } from 'store/store';
import { timezones } from 'utils/timezone';

const AccountDetailsPage = () => {
  const { myAccount } = useMyAccount();
  let { accountId } = useParams<{ accountId: string }>();
  const { accountSubscribed, account, accountLoading } = useAccount();
  const { accountManualPatches, accountManualPatchesLoading, accountManualPatchesSubscribed } =
    useAccountManualPatches(accountId);

  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();

  const [
    showModal,
    setShowModal,
  ] = useState<boolean>(false);
  const [
    timezone,
    setTimezone,
  ] = useState<keyof typeof timezones>('UZ');
  const [
    searchValue,
    setSearchValue,
  ] = useState('');
  const [updateForm] = useForm<UpdateAccountPayload>();

  useAuthConnectionEffect(() => {
    dispatch(AccountSlice.subscribe({ id: accountId }));
    dispatch(AccountManualPatchSlice.subscribe(accountId, { includeFinished: true }));

    return () => {
      dispatch(AccountSlice.unsubscribe({ id: accountId }));
      dispatch(
        AccountManualPatchSlice.unsubscribe(accountId, {
          includeFinished: true,
        })
      );
    };
  }, [accountId]);

  useEffect(() => {
    if (account && showModal) {
      updateForm.resetFields();
    }
  }, [
    account,
    showModal,
  ]);

  const filteredAccountManualPatches = useMemo(() => {
    return accountManualPatches?.filter(
      (entry) =>
        entry?.ownerId?.toLowerCase().search(new RegExp(searchValue.toLowerCase())) !== -1 ||
        entry?.driverName?.toLowerCase().search(new RegExp(searchValue.toLowerCase())) !== -1
    );
  }, [
    accountManualPatches,
    searchValue,
  ]);

  if (account) {
    const disabled = account.disabledAt;
    const disableAvailable = [
      'disabled',
      'offline',
      'online',
    ].includes(account.status);
    const logoutAvailable = account.status === 'online';

    return (
      <Layout className={'accounts-list-container'}>
        <Spin spinning={!accountSubscribed || accountLoading}>
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title={account.name}
            // subTitle="This is a subtitle"
            extra={
              myAccount?.role === 'admin'
                ? [
                    <Popconfirm
                      disabled={!logoutAvailable}
                      key="logoutAccountButton"
                      title={'Close session?'}
                      onConfirm={async () => {
                        const response = await appDispatch(logout(account));
                        if (response.status == 'ok') {
                          message.success(`Session killed`);
                        } else {
                          message.error('Cannot kill session');
                        }
                      }}
                      okText="YES"
                      cancelText="NO"
                    >
                      <Button type="primary" danger disabled={!logoutAvailable} key="3">
                        LOGOUT
                      </Button>
                    </Popconfirm>,
                    <Popconfirm
                      disabled={!disableAvailable}
                      key="disableButton"
                      title={`${account.disabledAt ? 'ENABLE' : 'DISABLE'} account?`}
                      onConfirm={async () => {
                        const response = await appDispatch(account.disabledAt ? enable(account) : disable(account));
                        if (response.status == 'ok') {
                          message.success(`Account ${account.disabledAt ? 'enabled' : 'disabled'}`);
                        } else {
                          message.error('Cannot disable account');
                        }
                      }}
                      okText="YES"
                      cancelText="NO"
                    >
                      <Button disabled={!disableAvailable}>{account.disabledAt ? 'ENABLE' : 'DISABLE'}</Button>
                    </Popconfirm>,
                    <Popconfirm
                      title="DELETE account?"
                      key="deleteButton"
                      onConfirm={async () => {
                        const response = await appDispatch(deleteAccount(account));

                        if (response.status === 'ok') {
                          message.success('Account is deleted');
                        } else {
                          message.error('Cannot delete account');
                        }
                      }}
                      okText="YES"
                      cancelText="NO"
                    >
                      <Button danger={true}>DELETE</Button>
                    </Popconfirm>,
                    <Button key="editButton" type="primary" onClick={() => setShowModal(true)}>
                      EDIT
                    </Button>,
                  ]
                : []
            }
          >
            <Descriptions>
              <Descriptions.Item label="USER FUNCTION">{accountRoleLabels[account.role]}</Descriptions.Item>
              <Descriptions.Item label="USERNAME">{account.username}</Descriptions.Item>
              <Descriptions.Item label="STATUS">
                <Tag color={disabled ? 'error' : accountColorByStatus[account.status]}>
                  {account.status.toUpperCase()}
                </Tag>
              </Descriptions.Item>
            </Descriptions>
          </PageHeader>
        </Spin>
        <Modal
          visible={showModal}
          title={`EDIT ${account.username}`}
          okText="EDIT"
          cancelText="CANCEL"
          onCancel={() => {
            setShowModal(false);
          }}
          onOk={() => {
            updateForm.submit();
          }}
          confirmLoading={account.loading}
        >
          <AccountForm
            showRole
            account={account}
            form={updateForm}
            onSubmit={async (updatedAccount) => {
              const response = await appDispatch(logout(account));
              if (response.status == 'ok') {
                message.success(`Session killed`);
              } else {
                message.error('Cannot kill session');
              }
              const { status, msg } = await appDispatch(
                update(
                  {
                    ...account,
                    ...updatedAccount,
                  },
                  account
                )
              );
              if (status === 'ok') {
                updateForm.resetFields();
                setShowModal(false);
                message.success('Account is changed');
              } else {
                message.error(msg || 'Error');
              }
            }}
          />
        </Modal>
        <Layout.Content>
          <Typography.Title style={{ margin: '16px 0' }} level={3}>
            ACTIVITY
          </Typography.Title>
          <div className={'content'} style={{ textAlign: 'center' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Radio.Group value={timezone} onChange={(e) => setTimezone(e.target.value)} style={{ marginBottom: 16 }}>
                <Radio.Button value="UZ">UZ</Radio.Button>
                <Radio.Button value="ET">ET</Radio.Button>
                <Radio.Button value="CT">CT</Radio.Button>
                <Radio.Button value="MT">MT</Radio.Button>
                <Radio.Button value="PT">PT</Radio.Button>
              </Radio.Group>
              <Input
                placeholder="Search Activity"
                value={searchValue}
                size={'middle'}
                style={{
                  width: '100px',
                  height: '32px',
                  minWidth: '100px',
                  marginRight: '8px',
                }}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
            </div>
            {filteredAccountManualPatches && myAccount && (
              <ManualPatchesTable
                loading={!accountManualPatchesSubscribed || accountManualPatchesLoading}
                account={myAccount}
                manualPatches={filteredAccountManualPatches}
                timezone={timezone}
              />
            )}
          </div>
        </Layout.Content>
      </Layout>
    );
  } else {
    return null;
  }
};
export default AccountDetailsPage;
