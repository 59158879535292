import { DependencyList, EffectCallback, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectConnection } from 'slices/connection';

export const useAuthConnectionEffect = (callback: EffectCallback, deps?: DependencyList) => {
  const { loading, authenticated } = useSelector(selectConnection);
  useLayoutEffect(() => {
    if (!loading && authenticated) {
      return callback();
    }
  }, [
    loading,
    authenticated,
    ...(deps || []),
  ]);
};

export const useConnection = () => {
  const { loading, authenticated } = useSelector(selectConnection);
  return { loading, authenticated };
};
