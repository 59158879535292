import { DriverNotePayload } from 'interfaces';
import { useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { ResponsePayload } from 'slices/subscriptive';
import { createSingleSubscriptiveSlice } from 'slices/subscriptive/single';
import { Socket } from 'socket.io-client';
import { RootState } from 'store/reducer';
import { emitAsync } from 'utils/socket';
import { v4 } from 'uuid';
import { setError } from './errors';

const { select, unsubscribe, reducer, onPublish, subscribe, selectResource, slice } = createSingleSubscriptiveSlice({
  name: 'driverNote',
  volatile: true,
  reducers: {},
});

const { setLoading } = slice.actions;

export default slice.reducer;

export class CreateDriverNotePayload {
  driverId: string;
  freeMonth: string;
  companyId: string;
}

const upsert =
  (driverNote: CreateDriverNotePayload) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<ResponsePayload<null | undefined>> => {
    const socket = getSocket();
    const clientResourceId = v4();

    return await emitAsync<ResponsePayload<null | undefined>>(socket, `driverNote:upsert`, driverNote);
  };

const deleteNote =
  (existingDriverNote: { driverId: string }) =>
  async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket): Promise<ResponsePayload> => {
    const socket = getSocket();

    const response = await emitAsync<ResponsePayload>(socket, `driverNote:delete`, {
      driverId: existingDriverNote.driverId,
    });

    if (response.status !== 'ok') {
      dispatch(setError({ status: response.status, msg: response.msg }));
    }

    return response;
  };

export { unsubscribe, reducer, onPublish, subscribe, slice, upsert, deleteNote };

export const useDriverNote = () => {
  const { resource, loading, subscribed } = useSelector(select);
  return {
    driverNote: resource as unknown as DriverNotePayload | undefined,
    driverNoteLoading: loading,
    driverNoteSubscribed: subscribed,
  };
};
