import { Modal, Radio, RadioChangeEvent, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { DriverPayload } from '../interfaces';

type UserSelectionModalProps = {
  isVisible: boolean;
  onConfirm: (selectedUserId?: string) => void;
  onCancel: () => void;
  availableUsers: DriverPayload[];
};
const UserSelectionModal: React.FC<UserSelectionModalProps> = ({ isVisible, onConfirm, onCancel, availableUsers }) => {
  const [
    selectedUserId,
    setSelectedUser,
  ] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (availableUsers.length > 0) {
      setSelectedUser(availableUsers[0]._id);
    }
  }, [availableUsers]);

  const onChangeSelectedUser = (e: RadioChangeEvent) => {
    setSelectedUser(e.target.value);
  };

  return (
    <Modal title="Select driver" visible={isVisible} onOk={() => onConfirm(selectedUserId)} onCancel={onCancel}>
      <Radio.Group onChange={onChangeSelectedUser} value={selectedUserId} defaultValue={selectedUserId}>
        <Space direction="vertical">
          {(availableUsers || []).map((user) => (
            <Radio key={user._id} value={user._id}>
              {user.firstName} {user.lastName}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </Modal>
  );
};

export default UserSelectionModal;
