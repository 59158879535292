import { VehiclePayload } from 'interfaces';
import * as r from 'ramda';
import { useSelector } from 'react-redux';
import { createRelativeSubscriptiveSlice } from 'slices/subscriptive/relative';

const { select, unsubscribe, reducer, reconnect, onPublish, subscribe, selectChildResourceList, slice } =
  createRelativeSubscriptiveSlice({
    name: 'vehicles',
    // @ts-expect-error _id does not exist
    idProp: '_id',
    payloadType: VehiclePayload,
    parentName: 'companies',
    parentSingleName: 'company',
    deletedFilterFn(resource): boolean {
      return resource.deletedAt != null;
    },
    reducers: {},
  });

export { unsubscribe, reducer, reconnect, onPublish, subscribe };

export default slice.reducer;

export const useVehicles = (accountId: string | null) => {
  const {
    resourceDictionary: vehiclesById,
    loading: vehiclesLoading,
    subscribed: vehiclesSubscribed,
  } = useSelector(r.partial(select, [accountId])) || {};

  let vehicles = useSelector(r.partial(selectChildResourceList, [accountId]));
  let onlyVehiclesById = vehiclesById;

  if (vehicles instanceof Array && vehicles.length > 0) {
    onlyVehiclesById = r.indexBy(
      r.prop('_id'),
      Object.values(vehiclesById as object).filter((vehicle) => vehicle.type.id === 'Vehicle')
    );
    vehicles = (vehicles as VehiclePayload[]).filter((vehicle) => vehicle.type.id === 'Vehicle');
  }

  return {
    vehicles: vehicles as VehiclePayload[] | undefined,
    vehiclesById: onlyVehiclesById as Record<string, VehiclePayload> | undefined,
    vehiclesLoading: vehiclesLoading,
    vehiclesSubscribed: vehiclesSubscribed,
  };
};
