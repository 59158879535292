import { UserAddOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useAuthConnectionEffect } from 'hooks/useAuthConnectionEffect';
import { AuditPayload, CompanyPayload, DriverPayload } from 'interfaces';
import moment, { Moment } from 'moment-timezone';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as AuditSlice from 'slices/audits';
import * as AuditsSlice from 'slices/audits';
import { CreateAuditPayload, useAudits } from 'slices/audits';
import * as CompaniesSlice from 'slices/companies';
import { useCompanies } from 'slices/companies';
import * as DriversSlices from 'slices/drivers';
import { useDrivers } from 'slices/drivers';
import { useMyAccount } from 'slices/myAccount';
import { useAppDispatch } from 'store/store';
import { compareTwoStrings } from 'string-similarity';
import { timezones } from 'utils/timezone';

const AuditsPage = () => {
  const [
    companyId,
    setCompanyId,
  ] = useState<string | null>(null);
  const { companiesById, companiesLoading, companiesSubscribed } = useCompanies();
  const { driversById, driversSubscribed, driversLoading } = useDrivers(companyId);
  const { audits, auditsSubscribed, auditsLoading } = useAudits();
  const { myAccount, myAccountLoading, myAccountSubscribed } = useMyAccount();

  const [form] = useForm<CreateAuditPayload>();
  const [
    showModal,
    setShowModal,
  ] = useState<boolean>(false);
  const [timezone] = useState<keyof typeof timezones>('UZ');
  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();

  useAuthConnectionEffect(() => {
    dispatch(AuditSlice.subscribe());
    return () => {
      dispatch(AuditSlice.unsubscribe());
    };
  }, []);

  useAuthConnectionEffect(() => {
    if (companyId) {
      dispatch(DriversSlices.subscribe(companyId));
      return () => {
        dispatch(DriversSlices.unsubscribe(companyId));
      };
    }
  }, [
    companyId,
  ]);

  useAuthConnectionEffect(() => {
    dispatch(CompaniesSlice.subscribe());
    return () => {
      dispatch(CompaniesSlice.unsubscribe());
    };
  }, []);

  return (
    <Spin spinning={auditsLoading || !auditsSubscribed || myAccountLoading || !myAccountSubscribed}>
      <Layout className={'accounts-list-container'}>
        <Layout.Content>
          <Row justify={'space-between'}>
            <Col span={6}>
              <Typography.Title style={{ margin: '16px 0' }} level={3}>
                AUDIT
              </Typography.Title>
            </Col>
            {[
              'admin',
              'manager',
            ].includes(myAccount?.role || '') ? (
              <Col
                span={3}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Button icon={<UserAddOutlined />} key="createButton" type="primary" onClick={() => setShowModal(true)}>
                  CREATE
                </Button>
                <Modal
                  visible={showModal}
                  title="CREATE AUDIT"
                  okText="CREATE"
                  cancelText="CANCEL"
                  onCancel={() => {
                    setShowModal(false);
                  }}
                  onOk={() => {
                    form.submit();
                  }}
                  confirmLoading={companiesLoading || !companiesSubscribed}
                >
                  <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    onFinish={async (values) => {
                      const { status, msg } = await appDispatch(AuditSlice.create(values));
                      if (status === 'ok') {
                        form.resetFields();
                        setShowModal(false);
                        message.success('Audit has been created');
                      } else {
                        message.error(msg || 'Error');
                      }
                    }}
                  >
                    <Form.Item name="to" label="To" hidden />
                    <Form.Item name="from" label="From" hidden />
                    <Form.Item
                      label="BLOCK PERIOD"
                      rules={[
                        {
                          required: true,
                          message: 'Choose lock period',
                        },
                      ]}
                    >
                      <DatePicker.RangePicker
                        onChange={(value: [Moment | null, Moment | null] | null) => {
                          const [
                            from,
                            to,
                          ] = value || [];
                          form.setFields([
                            {
                              name: 'from',
                              value: from ? moment.utc(from.format('YYYY-MM-DD')).toDate() : null,
                            },
                            {
                              name: 'to',
                              value: to ? moment.utc(to.format('YYYY-MM-DD')).toDate() : null,
                            },
                          ]);
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="companyId"
                      label="COMPANY"
                      className="collection-create-form_last-form-item"
                      rules={[
                        {
                          required: true,
                          message: 'Please choose company',
                        },
                      ]}
                    >
                      <Select
                        placeholder={'Select company'}
                        filterOption={(str, option) => {
                          const name = option?.children.toLowerCase().replaceAll(' ', '');
                          const searchStr = str.toLowerCase().replaceAll(' ', '');
                          return !str || name.includes(searchStr) || compareTwoStrings(name, searchStr) > 0.8;
                        }}
                        onChange={(companyId: string) => {
                          form.setFields([{ name: 'driverId', value: null }]);
                          setCompanyId(companyId);
                        }}
                        showSearch
                        getPopupContainer={(node: HTMLElement) => node.parentNode as HTMLElement}
                        style={{ minWidth: 300 }}
                      >
                        {Object.values(companiesById).map((company: CompanyPayload) => (
                          <Select.Option key={company._id} value={company._id}>
                            {company.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="driverId"
                      label="DRIVER"
                      className="collection-create-form_last-form-item"
                      rules={[
                        {
                          required: true,
                          message: 'Please choose driver',
                        },
                      ]}
                    >
                      <Select
                        loading={driversLoading}
                        disabled={!driversSubscribed}
                        placeholder={'Select driver'}
                        filterOption={(str, option) => {
                          const name = option?.children.join('').toLowerCase();
                          const searchStr = str.toLowerCase();
                          return !str || name.includes(str.toLowerCase()) || compareTwoStrings(name, searchStr) > 0.8;
                        }}
                        showSearch
                        getPopupContainer={(node: HTMLElement) => node.parentNode as HTMLElement}
                        style={{ minWidth: 300 }}
                      >
                        {Object.values(driversById || {}).map((driver: DriverPayload) => (
                          <Select.Option key={driver._id} value={driver._id}>
                            {driver.firstName} {driver.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="message"
                      label="DESCRIPTION"
                      rules={[
                        {
                          required: true,
                          message: 'Description is required',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Form>
                </Modal>
              </Col>
            ) : null}
          </Row>
          <div className={'content'}>
            <Table
              pagination={{
                pageSizeOptions: [
                  '10',
                  '50',
                  '100',
                  '250',
                  '500',
                  '1000',
                ],
                showSizeChanger: true,
              }}
              dataSource={audits}
            >
              <Table.Column
                title="DRIVER"
                dataIndex="driverName"
                key="driverName"
                render={(driverName: string) => {
                  return driverName ? <Tooltip title={driverName}>{driverName}</Tooltip> : '-';
                }}
              />
              <Table.Column
                title="COMPANY"
                dataIndex="companyName"
                key="companyName"
                render={(companyName: string, data) => {
                  return companyName ? <Tooltip title={companyName}>{companyName}</Tooltip> : '-';
                }}
              />
              <Table.Column
                title="PERIOD"
                dataIndex="from"
                key="from"
                render={(_, auditPayload: AuditPayload) => {
                  return `${auditPayload.from ? moment(auditPayload.from).format('DD.MM.YYYY') : 'N/A'}-${
                    auditPayload.to ? moment(auditPayload.to).format('DD.MM.YYYY') : 'N/A'
                  }`;
                }}
              />

              <Table.Column
                title="CREATED (DATE/TIME)"
                dataIndex="createdAt"
                key="createdAt"
                render={(createdAt: string) => {
                  return createdAt ? moment(createdAt).tz(timezones[timezone]).format('DD.MM.YYYY HH:mm') : '-';
                }}
              />

              {[
                'admin',
                'manager',
              ].includes(myAccount?.role || '') ? (
                <Table.Column
                  dataIndex="clientResourceId"
                  key="clientResourceId"
                  render={(clientResourceId, audit: AuditPayload) => (
                    <Button
                      onClick={async () => {
                        const { status, msg } = await appDispatch(AuditsSlice.deleteAudit(audit));
                        if (status === 'ok') {
                          message.success('Audit has been deleted');
                        } else {
                          message.error(msg || 'Error');
                        }
                      }}
                    >
                      DELETE
                    </Button>
                  )}
                />
              ) : null}
            </Table>
          </div>
        </Layout.Content>
      </Layout>
    </Spin>
  );
};
export default AuditsPage;
